<template>
    <div>
        <div class="roleBox">
            <div class="avatar-div" id="avatarDiv" style="width:200px; height:400px;" ar></div>
            <div class="row">
                <div>
                    <span>角色名称：</span>
                    <el-input v-model="UserName" placeholder="请输入名称"></el-input>
                </div>
                <div>
                    <span>角色性别：</span>
                    <el-select v-model="gendervalue" @change="genderBtn()" placeholder="请选择性别">
                        <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <span>角色服装：</span>
                    <el-select v-model="clothingvalue" @change="clothingBtn()" placeholder="请选择服装">
                        <el-option v-for="item in clothing" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <el-button type="primary" @click="NextBtn()">下一步</el-button>
        </div>
    </div>
</template>

<script>
import { AmendUser } from "../../utils/api/http.js";
import { setStorage, getStorage } from "../../utils/token/token.js";
import { GetUrl } from "../../utils/utils.js";
export default {
    data() {
        return {
            gender: [
                {
                    value: '0',
                    label: '男'
                },
                {
                    value: '1',
                    label: '女'
                },
            ],
            gendervalue: '0',
            clothing: [
                {
                    value: '1',
                    label: '1'
                },
                {
                    value: '2',
                    label: '2'
                },
                {
                    value: '3',
                    label: '3'
                },
                {
                    value: '4',
                    label: '4'
                },
                {
                    value: '5',
                    label: '5'
                },
            ],
            clothingvalue: '1',
            UserName: '',
            roomID: null,
            SecneId: null,
        }
    },
    methods: {
        // 性别选择
        genderBtn() {
            this.onAvatarChange();
        },
        // 服装选择
        clothingBtn() {
            this.onAvatarChange();
        },
        onAvatarChange() {
            ModelViewer.ShowAvatar(this.gendervalue, this.clothingvalue);
        },
        // 下一步
        NextBtn() {
            let data = {
                id: getStorage('UserId'),
                phone: '',
                name: this.UserName,
                information: '',
                position: '',
                sex: this.gendervalue,
                hair: '',
                face: '',
                jacket: '',
                garment: this.clothingvalue,
                isFirst: false
            }
            AmendUser(data).then(res => {
                if (res.code == 200) {
                    setStorage('Sex', res.data.sex)
                    setStorage('Garment', res.data.garment)
                    setStorage('UserName', res.data.name)
                    if (!this.roomID) {
                        this.$router.push({
                            path: '/Index',
                            query: {
                                roomId: this.roomID,
                                SecneId: this.SecneId
                            }
                        })
                    } else {
                        setStorage('roomId', this.roomID)
                        this.$router.push({
                            path: '/Preview',
                            query: {
                                roomId: this.roomID,
                                SecneId: this.SecneId
                            }
                        })
                    }
                }
            })
        }
    },
    mounted() {
        this.roomID = GetUrl('roomId')
        this.SecneId = GetUrl('SecneId')
        let avatarDiv = document.getElementById("avatarDiv");
        ModelViewer.InitAvatar(avatarDiv, function () {
            /**
             * ShowAvatar：显示角色接口
             * 参数1：0男 1女
             * 参数2: 1-5 服装ID
            */
            ModelViewer.ShowAvatar(0, 1);
        });
    }
}
</script>

<style scoped>
.roleBox {
    width: 25%;
    min-width: 360px;
    margin: 0 auto;
}

/* .row{
        margin: 0 auto;
    } */
</style>