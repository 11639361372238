<template>
  <div class="container_box">
    <div class="Scene_Box">
      <div class="Header_box">
        <h1>场景列表</h1>
      </div>
      <div class="AddBtn">
        <el-button type="primary" @click="centerDialogVisible = true">创建场景</el-button>
        <el-button type="primary" @click="SenceupDialogVisible = true">新模板上传</el-button>
      </div>
      <div class="content_box">
        <div class="content_list" v-for="(item, index) in DataList" :key="index">
          <el-image :src="item.logoImage">
          </el-image>
          <p>{{ item.compositionName }}</p>
          <div class="Btn_list">
            <el-button size="small" type="primary" @click="EditBtn(item)">编辑</el-button>
            <el-button size="small" type="primary" @click="Preview(item)">预览</el-button>
            <el-button size="small" type="danger" @click="DelSceneBtn(item)">删除</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加场景 -->
    <el-dialog title="新模板上传" :visible.sync="SenceupDialogVisible">
      <div>
        <SenceUp @close="close" @GetTemplateList="GetTemplateList"></SenceUp>
      </div>
    </el-dialog>
    <!-- 创建弹窗 -->
    <el-dialog title="场景创建" :visible.sync="centerDialogVisible" width="30%" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="名称" prop="SceneName">
          <el-input v-model="ruleForm.SceneName" placeholder="请输入场景名称"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="filePath">
          <el-select v-model="ruleForm.filePath" @change="SelectVal" clearable placeholder="请选择场景类型">
            <el-option v-for="item in options" :key="item.index" :label="item.modelName" :value="item.filePath">
              <img width="120px" :src="item.logoImage" alt="">
              <span class="SecneName">{{ item.modelName }}</span>
              <!-- <el-radio v-model="ruleForm.modelType" :label="item.modelType">{{ }}</el-radio> -->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预览图" prop="SceneImg">
          <div class="form-item" v-loading="loading" element-loading-text="上传中，请稍后..."
            element-loading-spinner="el-icon-loading">
            <img v-if="ruleForm.SceneImg" class="ShowSceneimg" :src="ruleForm.SceneImg" alt="" />
            <el-upload ref="upload" v-if="Upshow" class="upload" action="#" drag :auto-upload="false"
              accept=".png,.jpeg,.jpg" :file-list="fileList" :show-file-list="false" :onChange="pngChange"
              :before-upload="handleBeforeUpload" :limit="1">
              <div class="upload-icon img_default"></div>
              <div class="upload-tip p1">点击上传</div>
              <!-- <div class="upload-tip2 p1">（仅支持png格式）</div> -->
            </el-upload>
            <div v-if="Upshow" class="tips p5">
              （仅支持jpg/png格式图片）
            </div>
            <!-- <div class="tips p5">
                  （仅支持jpg/png格式图片，尺寸：1920*950px）
                </div> -->
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
      </span>
    </el-dialog>
    <div class="Buttonpagination">
      <el-pagination id="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="params.currentPage" :page-sizes="[8, 4]" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { DataList, UploadScenePicture, AddScene, DelScene, TemplateList, SenceZipUp, readZipUp } from "../utils/api/http";
import { handleCofirm } from "../utils/confirm/confirm.js";
import { getStorage } from "../utils/token/token.js";
import SenceUp from '../components/upload/NewSence.vue';
export default {
  components: {
    SenceUp
  },
  data() {
    return {
      List: [],
      DataList: [],
      centerDialogVisible: false,
      SenceupDialogVisible: false,
      ruleForm: {
        SceneName: '',
        modelType: null,
        SceneImg: '',
        filePath:'',
      },
      loading:false,
      Upshow: true,
      fileList: [],
      fileLists: [],
      total: null,
      params: {
        currentPage: 1,
        pageSize: 8,
      },
      options: [],
      rules: {
        SceneName: [
          { required: true, message: '请输入场景名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        filePath: [
          { required: true, message: '请选择场景类型', trigger: 'blur' },
        ],
        SceneImg: [
          { required: true, message: '请上传场景缩略图', trigger: 'blur' },
        ],
      }
    }
  },
  watch: {
    centerDialogVisible(newName, oldName) {
      if (!newName) {
        this.Upshow = true;
        this.ruleForm.SceneName = '';
        this.ruleForm.modelType = null;
        this.ruleForm.SceneImg = '';
      }
    }
  },
  methods: {
    SelectVal(val) {
      // console.log(val);
    },
    // 获取数据
    MakeBtn() {
      let data = {
        composition: {
          userId: window.localStorage.getItem('UserId'),
          phone: ""
        },
        pageNum: this.params.currentPage,
        pageSize: this.params.pageSize,
      }
      DataList(data).then(res => {
        // console.log(res, '初始化数据');
        this.total = res.data.total;
        this.DataList = res.data.list;
      })
    },
    // 获取模板数据
    GetTemplateList() {
      let data = {
        id: "",
        modelType: ""
      }
      TemplateList(data).then(res => {
        this.options = res.data
        // console.log(res, '模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板模板');
      })
    },
    // 点击编辑跳转
    EditBtn(val) {
      // 跳转编辑界面之前获取最新数据
      // console.log(val);

      let data = {
        composition: {
          userId: "",
          phone: "",
          compositionId: val.compositionId,
        },
        pageNum: 1,
        pageSize: 1,
      }
      DataList(data).then(res => {
        if (res.code == 200) {
          window.localStorage.setItem('filePath', val.filePath);
          window.localStorage.setItem('UrlDAta', res.data.list[0].modelInformation);
          window.localStorage.setItem('SecneId', res.data.list[0].compositionId);
          window.localStorage.setItem('SecneType', res.data.list[0].modelType);
          let routeUrl = this.$router.resolve({
            path: "/HomeView",
            query: {
              SecneId: val.compositionId,
            }
          });
          window.open(routeUrl.href, '_blank');
        }
      })
    },
    // 点击删除
    DelSceneBtn(val) {
      handleCofirm("确定要删除这条场景信息吗？").then((res) => {
        let data = {
          compositionId: val.compositionId,
          userId: window.localStorage.getItem('UserId')
        }
        DelScene(data).then(res => {
          // console.log(res);
          if (res.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.MakeBtn();
          }
        })
      }).catch(res => {
        this.$message({
          message: '取消删除',
          type: 'warning'
        });
      });
    },
    // 点击预览
    Preview(val) {
      // console.log(val);
      // debugger
      let data = {
        composition: {
          userId: "",
          phone: "",
          compositionId: val.compositionId,
        },
        pageNum: 1,
        pageSize: 1,
      }
      DataList(data).then(res => {
        // console.log(res);
        if (res.code == 200) {
          // console.log(res.data.list[0].modelInformation);
          window.localStorage.setItem('filePath', val.filePath);
          window.localStorage.setItem('UrlDAta', res.data.list[0].modelInformation);
          window.localStorage.setItem('SecneId', res.data.list[0].compositionId);
          window.localStorage.setItem('SecneType', res.data.list[0].modelType);
          window.localStorage.setItem('roomId', res.data.list[0].roomId);
          // window.localStorage.setItem('Modeltype', res.data.list[0].modelType);
          let routeUrl = this.$router.resolve({
            path: "/Preview",
            query: {
              playerName: res.data.list[0].compositionName,
              SecneId: res.data.list[0].compositionId,
              roomId: res.data.list[0].roomId
            }
          });
          window.open(routeUrl.href, '_blank');
        }
      })
    },
    // 图片上传
    pngChange(file) {

      let fileType = ["png", "jpg", "jpeg"];
      this.fileCheck(file, fileType, 1);
    },
    fileCheck(file, fileType, fileSide) {
      let suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileType.indexOf(suffix) < 0) {
        this.$message.error("文件类型格式不对！");
        this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        return;
      }
      if (file.side > fileSide * 1024 * 1024) {
        this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        this.$message.error(`文件大小不能超过 ${fileSide} MB！`);
        return;
      }
      let params = {
        file: file.raw,
      };
      this.loading=true;
      // console.log(params);
      UploadScenePicture(params).then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.ruleForm.SceneImg = res.data.url;
          this.Upshow = false;
          this.$message.success("上传成功！");
          this.loading=false;
        }
        // this.GetImgList();
      });
    },
    // 判断上传的是否为图片
    handleBeforeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");

        return false;
      }
      // 可以限制图片的大小
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");

      }

      return suffix || suffix2 || suffix3;
    },
    // 表单验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          roomMgr.AddRoom(getStorage('UserId')).then(res => {
            // console.log(res);
            let data = {
              userId: window.localStorage.getItem('UserId'),
              modelType: this.ruleForm.modelType,
              compositionName: this.ruleForm.SceneName,
              description: "场景说明",
              logoImage: this.ruleForm.SceneImg,
              information: res.information,
              filePath: this.ruleForm.filePath,
            }
            AddScene(data).then(res => {
              if (res.code == 200) {
                // console.log(res);
                this.$message.success(`场景添加成功！`);
                this.centerDialogVisible = false;
                this.MakeBtn();
              }
            })
          })

        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 分页
    handleSizeChange(val) {
      // this.listLoading = true;
      this.params.pageSize = val;
      this.MakeBtn();
    },
    handleCurrentChange(val) {
      // this.listLoading = true;
      this.params.currentPage = val;
      this.MakeBtn();
    },
    // 添加成功关闭弹窗
    close() {
      this.SenceupDialogVisible = false
    },
  },
  mounted() {
    this.MakeBtn();
    this.GetTemplateList();
    // if (true) console.log('true+++++++++++++++++++++++++++++'), console.log('11111');
    // console.log(this.axios,'++++++');
    // let url='http://192.168.1.14:8090/scene/e5c9e02300be239ce8f6c5e7844d5183/project.json'
    // let text=  readZipUp(url)
    // console.log(text);
  }
}
</script>

<style  lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}

.container_box {
  width: 100%;
  /* height: 100%; */
  height: auto;
}

.Scene_Box {
  width: 100%;
  /* height: 100%; */
  height: auto;
}

.Header_box {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 30px;
}

.AddBtn {
  text-align: right;
  padding-right: 180px;
}

.content_box {
  width: 1200px;
  height: auto;
  //  background: #ccc; 
  margin: 30px auto 0;
  display: flex;
  flex-wrap: wrap;
}

.content_list {
  text-align: center;
  width: 240px;
  height: auto;
  margin: 30px;
  padding: 10px;
  border: 1px solid #ccc;
}

.Btn_list {
  padding-top: 10px;
}

.el-select {
  width: 100%;
}

.ShowSceneimg {
  width: 45%;
  height: 180px;
}

.el-image {
  width: 100%;
  height: 136px;
  /* height: 100%; */
}

::v-deep .image-slot {
  width: 100%;
  height: 100%;
}

::v-deep .el-upload {
  width: 45%;
}

::v-deep .el-upload-dragger {
  width: 100%;
  min-width: 180px;
}

::v-deep .el-dialog {
  min-width: 360px;
}

.Buttonpagination {
  text-align: center;
}

.SelectScene {
  display: flex;

  .SelectBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 6px;
  }
}

.SecneName {
  display: inline-block;
  margin-left: 12px;
}

::v-deep .el-select-dropdown__item {
  margin: 6px 0;
}

.el-select-dropdown__item {
  height: 72px !important;
  padding: 0;
}
</style>