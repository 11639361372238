<template>
  <div >
    <div class="fillcontain" v-loading="loading" element-loading-text="上传中，请稍后..."
                    element-loading-spinner="el-icon-loading">
            <img v-show="!this.UploadShow" width="20%" style="min-width:120px" height="180px" :src="this.imgurl" alt="" />
            <el-upload ref="upload" v-show="this.UploadShow" class="upload" action="#" drag accept=".png,.svg,.gif,.jpeg,.jpg" :auto-upload="false"
              :file-list="fileList" :show-file-list="false" :onChange="pngChange" :before-upload="handleBeforeUpload"
              :limit="5">
              <div class="upload-icon"></div>
              <div class="upload-tip p1">点击上传</div>
              <div class="upload-tip2 p1">（仅支持jpg,png,jpeg格式）</div>
            </el-upload>
          </div>
  </div>
</template>
<script>
import { SenceImageUpload,  } from '../../utils/api/http.js'
export default {
  props:{
    Getimgurl:{
      type:Function,
    }
  },
  data() {
    return {
      imgurl: '',
      fileList: [],
      UploadShow: true,
      loading:false,
    }
  },
  methods: {
    // 上传
    pngChange(file) {
      let fileType = ["png", "jpg", 'jpeg'];
      this.fileCheckimg(file, fileType, 1);
      if (!this.imgType) {
      }
    },
    fileCheckimg(file, fileType, fileSide) {
      let suffix = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase(); // 防止有的文件后缀名是大写  好
      if (fileType.indexOf(suffix) < 0) {
        this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        this.$message.error("文件类型格式不对！");
        return false;
      }
      if (file.side > fileSide * 1024 * 1024) {
        this.$message.error(`文件大小不能超过 ${fileSide} MB！`);
        return;
      }
      let params = {
        file: file.raw,
        materialType: "0",
      };
      this.loading=true;
      SenceImageUpload(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("上传成功");
          this.imgurl = res.data.url
          this.UploadShow = !this.UploadShow
          // console.log(params, '55555555555555');
          this.Getimgurl(res.data.url)
          this.loading=false;
        }
      });
    },
    // 判断上传的是否为图片
    handleBeforeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "png";
      const suffix2 = img === "jpg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!suffix && !suffix2) {
        this.$message.error("只能上传图片！");
        return false;
      }
      // 可以限制图片的大小
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return suffix || suffix2 || suffix3;
    },
    successUpload(response, file, fileList) {
    this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
},
  },
  mounted() {
  }
}
</script>

<style scoped>
::v-deep .el-upload {
  width: 100%;
}

::v-deep .el-upload-dragger {
  width: 100%;
}
</style>