
// 从URl里面获取参数
export function GetUrl(objName){
  var data = window.location.href;
  if(data.indexOf("?")<0) return undefined; //判断是否存在参数
  var allParamsArr = data.split("?")[1].split("&"), returnObj = {};
  if(allParamsArr.length==0) return undefined; //参数是否带惨，
  for(var i =0; i<allParamsArr.length; i++) {
      returnObj[`${allParamsArr[i].split("=")[0]}`] = allParamsArr[i].split("=")[1]
  }
  return returnObj[`${objName}`]
}
// 一键复制到粘贴板
export function Copy(val){
  var input = document.createElement("input");
  const url = val;
  input.value = url; 
  document.body.appendChild(input); 
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input); 
}

//  
export function Getitem(val){
    return window.localStorage.getItem(val)
}
   
export function Setitem(val){
    return window.localStorage.setItem(val)
}
   
