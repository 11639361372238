<template>
    <div class="Nav-a">
        <div class="LeftList">
            <Left ref="LiList"></Left>
        </div>
        <div class="center" id="wed3d">

        </div>
    </div>
</template>
  
<script>
let Testdata = null
import Left from '../components/left.vue';
// import { DataList } from "../utils/api/http.js";
export default {
    components: {
        Left
    },
    data() {
        return {
            ListData: [],
            sceneData: null,
            filePath:'',
        }
    },
    created() {
        this.sceneData = JSON.parse(window.localStorage.getItem('UrlDAta'));
        this.filePath = window.localStorage.getItem('filePath');
    },
    mounted() {
        // 获取Blob
        let path = window.localStorage.getItem('UrlDAta');
        // console.log(window.localStorage.getItem('UrlDAta'), '+++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
        window.EDITOR = false;
        window.globalVars = {
        };
        const app = new HotSpotEditor.HotSpotEditorApplication(
            $("<div id='renderArea' class=\"render-area-landscape\"></div>").appendTo("#wed3d")
        );
        // const loginTime = new Date();
        // const randomUid = `${loginTime.getMinutes()}-${loginTime.getSeconds()}`;
        const projConfig = {
            projectFolder: this.filePath + '/',
            // mainScene: "Assets/Scene/GalaxyMuseumV3.scene",
            sceneData: JSON.parse(window.localStorage.getItem('UrlDAta')),
            mode: "edit",
            // mmoOptions: {
            //     playerName: "Anonymous", //与会者名称（必选）
            //     gender: 0,    //与会者性别
            //     spaceID: "1",   //场地ID
            //     roomID: "PublicRoom",    //房间ID
            //     avatarID: 0,
            //     userName: `用户${randomUid}`,   //网络认证用户名
            //     // password?: string,   //网络认证密码

            //     meta: {
            //         id: randomUid,
            //         title: `BigBoss`,
            //         hairColor: '2',
            //         shirtColor: '2',
            //         trousersColor: '2',
            //     }
            // }
        };
        app.loadProject(projConfig).then((compMap) => {

            if (compMap) {
                // globalVars.mmoManager = new MMOManager(projConfig.mmoOptions);
                // globalVars.mmoManager.setupNetwork();
                let dataList = [];
                // 拿到左边的列表
                let LeftList = globalVars.app.getEditableComponentsInfo();
                globalVars.app.registerComponentFocusedEvent(this.One);
                globalVars.app.registerComponentUnfocusedEvent(this.Two);
                for (let i = 0; i < LeftList.length; i++) {
                    // console.log(LeftList[i].name,'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii');
                    dataList.push({
                        name: LeftList[i].name,
                        id: LeftList[i].id,
                    })
                }
                // this.$bus.$emit('list', LeftList)
                this.$refs.LiList.GetList(dataList);
                this.ListData = dataList;
            }
        });
    },
    methods: {
        One(val) {
            // console.log('选中');
            this.$store.commit('savePath', true);
            this.$store.commit('saveId', val);
            // console.log(val,'data');
            for (let i = 0; i < this.ListData.length; i++) {
                if (this.ListData[i].id === val) {
                    this.$store.state.leftactive = i;
                    //  console.log(this.ListData[i], '++++++++++++++++++++++++++++++++++++++++++++++++++');
                    break
                }
                // console.log('出循环');
            }
            // this.$bus.$emit('', true)
        },
        Two(val) {
            this.$store.commit('savePath', false);
            this.$store.commit('saveId', null);
            // console.log('离开');
            // console.log(val);
        },
    }
}
</script>
  
<style scoped>
.Nav-a {
    height: 100%;
    display: flex;
}

.LeftList {
    left: 0;
    width: 280px;
    height: 100%;
    /* overflow-y: scroll; */
    background: rgb(34, 34, 34);
}

.center {
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
}
</style>