<template>
  <div class="imgcontent">
    <div class="imgBox">
      <div class="imgupload imguploadBtn" @click="imgBtn">
        +
      </div>
      <div class="imgupload" v-for="(item, index) in imgList" @click="imgDataBtn(item)" :key="index">
        <p class="ImgName">{{ item.name | ellipsis }}</p>
        <img width="100%" height="100%" :src="item.smallUrl" alt="">
      </div>
    </div>
    <div class="Buttonpage" v-if="imgList.length > 0">
      <el-pagination small background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="params.currentPage" layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <el-dialog @close="CloseDialog" :lock-scroll="false" :close-on-click-modal="false" title="资源上传"
      :visible.sync="centerDialogVisible" width="30%" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="模型名称" prop="name">
          <div class="fillcontain"  v-loading="loading" element-loading-text="上传中，请稍后..."
            element-loading-spinner="el-icon-loading">
            <img v-show="!this.UploadShow" width="80%" style="min-width:120px" height="180px" :src="this.imgurl" alt="" />
            <el-upload ref="upload" v-show="this.UploadShow" class="upload" action="#" drag accept=".png,.svg,.gif,.jpeg,.jpg" :auto-upload="false"
              :file-list="fileList" :show-file-list="false" :onChange="pngChange" :before-upload="handleBeforeUpload"
              :limit="5">
              <div class="upload-icon"></div>
              <div class="upload-tip p1">点击上传</div>
              <div class="upload-tip2 p1">（仅支持jpg,png,jpeg格式）</div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <!-- <el-button @click="UploadBtn">下一步</el-button> -->
        <!--  如何在使用VUE技术栈中对接3D场景进行性能优化 -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ImageUpload, GetList } from '../../utils/api/http.js';
import { getStorage } from '../../utils/token/token.js';
export default {
  props:{
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 6) {
        return value.slice(0, 6) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      total: null,
      params: {
        currentPage: 1,
        goodsName: "",
        pageSize: 9,
        supplierGoodsId: "",
      },
      loading:false,
      imgList: [],
      imgurl: '',
      input1: '',
      input2: '',
      input3: '',
      select: '',
      fileList: [],
      activeName: 'second',
      centerDialogVisible: false,
      UploadShow: true,
      ruleForm: {
        name: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    imgBtn() {
      this.centerDialogVisible = true
      // this.$emit('ShowPopBox')
    },
    GetimgList() {
      let data = {
        userFile: {
          userId: getStorage('UserId'),
          phone: "",
          fileType: "0",
          compositionId:getStorage('SecneId'),
        },
        pageNum: this.params.currentPage,
        pageSize: this.params.pageSize
      }
      GetList(data).then(res => {
        if (res.code == 200) {
          this.total = res.data.total
          this.imgList = res.data.list
        }
      })
    },
    // 上传
    pngChange(file) {
      let fileType = ["png", "jpg", 'jpeg'];
      this.fileCheckimg(file, fileType, 1);
      if (!this.imgType) {
      }
    },
    fileCheckimg(file, fileType, fileSide) {
      let suffix = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase(); // 防止有的文件后缀名是大写  好
      if (fileType.indexOf(suffix) < 0) {
        this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        this.$message.error("文件类型格式不对！");
        return false;
      }
      if (file.side > fileSide * 1024 * 1024) {
        this.$message.error(`文件大小不能超过 ${fileSide} MB！`);
        return;
      }
      this.loading=true;
      let params = {
        file: file.raw,
        materialType: "0",
        compositionId:localStorage.getItem("SecneId"),
      };
      // console.log(params, '55555555555555');
      debugger
      ImageUpload(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("上传成功");
          this.imgurl = res.data.fileUrl
          this.UploadShow = !this.UploadShow
          this.GetimgList();
          this.centerDialogVisible=false
          this.loading=false;
        }
      });
    },
    // 判断上传的是否为图片
    handleBeforeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "png";
      const suffix2 = img === "jpg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!suffix && !suffix2) {
        this.$message.error("只能上传图片！");
        return false;
      }
      // 可以限制图片的大小
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return suffix || suffix2 || suffix3;
    },
    successUpload(response, file, fileList) {
    this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
},
    // 分页
    handleSizeChange(val) {
      this.listLoading = true;
      this.params.pageSize = val;
      this.GetimgList()
    },
    handleCurrentChange(val) {
      this.listLoading = true;
      this.params.currentPage = val;
      this.GetimgList()
    },
    // 点击视频
    imgDataBtn(data) {
      console.log(data);
      if (this.$store.state.Yesedit && this.$store.state.HotspotId) {
        //  console.log(data.fileUrl);
        //  console.log(this.$store.state.HotspotId);
        globalVars.app.setComponentAsset(this.$store.state.HotspotId, data.fileUrl, data.fileUrl)
        //  视频
        //  globalVars.app.setComponentAsset(this.$store.state.HotspotId,data.fileUrl)
      } else {
        this.$message({
          message: '请选择热点然后点击进行更换',
          type: 'warning'
        })
        // console.log(this.$store.state.Yesedit);
        // console.log('请先选择要修改的热点');
      }
    },
    //弹框关闭 
    CloseDialog() {
      this.fileList = [];
      this.imgurl = '';
      this.UploadShow = true
    }
  },
  mounted() {
    this.GetimgList()
  }
}
</script>

<style scoped>
.imgcontent {
  width: 100%;
  height: 100%;
}

.imgBox {
  height: 100%;
  width: calc(100% - 10px);
  padding: 0 5px;
  display: flex;
  flex-wrap: wrap;
}

.imgupload {
  position: relative;
  margin: 10px;
  width: 100px;
  height: 120px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  /* background: rgb(70, 80, 91); */
  font-size: 24px;
  color: #ccc;
}

.imguploadBtn {
  background: rgb(70, 80, 91);
  line-height: 120px;
}

.imgupload .ImgName {
  position: absolute;
  font-size: 16px;
  width: 100%;
  height: 24px;
  z-index: 1;
  bottom: 0;
  background: #ccc;
  color: #000;
}

.Buttonpage {
  text-align: center;
  /* position: absolute;
  bottom: 20px;
  margin-bottom: 0px; */
}

::v-deep .el-upload {
  width: 100%;
}

::v-deep .el-upload-dragger {
  width: 100%;
}
</style>