<template>
  <div class="header">
    <div class="headBox">
      <div class="head-left">
        <span>当前模板：{{ Name }}</span>
      </div>
      <div class="head-right">
        <!-- <el-button type="primary">发布</el-button> -->
        <el-button type="primary" @click="Preview">预览</el-button>
        <el-button type="primary" @click="SaveBtn">保存</el-button>
        <!-- <el-button type="primary" icon="el-icon-close"></el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { UpdateExhibition, DataList } from "../utils/api/http.js";
export default {
  data() {
    return {
      Name: ''
    }
  },
  methods: {
    SaveBtn() {
      // console.log(globalVars.app.exportSceneJsonData());
      // 获取修改后的3D场景数据
      let data = {
        compositionId: window.localStorage.getItem('SecneId'),
        modelInformation: JSON.stringify(globalVars.app.exportSceneJsonData()),
        userId: window.localStorage.getItem('UserId'),
        modelType: window.localStorage.getItem('SecneType'),
        status: '',
        creator: "",
        createTime: '',
        updater: '',
        updateTime: ''
      };
      // console.log(data);
      UpdateExhibition(data).then(res => {
        if (res.code == 200) {
          console.log(res);
          window.localStorage.setItem('UrlDAta', res.data.modelInformation);
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        }
      })
    },
    // 预览
    Preview() {
      // 点击跳转前获取最新数据
      let data = {
        composition: {
          userId: "",
          phone: "",
          compositionId: window.localStorage.getItem('SecneId'),
        },
        pageNum: 1,
        pageSize: 1,
      }
      DataList(data).then(res => {
        if (res.code == 200) {
          window.localStorage.setItem('UrlDAta', res.data.list[0].modelInformation);
          window.localStorage.setItem('SecneId', res.data.list[0].compositionId);
          window.localStorage.setItem('SecneType', res.data.list[0].modelType);
          let routeUrl = this.$router.resolve({
            path: "/Preview",
            query: {
              playerName: res.data.list[0].compositionName,
              SecneId: res.data.list[0].compositionId,
            }
          });
          window.open(routeUrl.href, '_blank');
        }
      })

      // let routeUrl = this.$router.resolve({
      //   path: "/Preview",
      //   // query: { id: 96 }
      // });
      // window.open(routeUrl.href, '_blank');
    }
  },
  mounted() {
    this.$nextTick(function () {
      let SceneName = globalVars.app.exportSceneJsonData()
      this.Name = SceneName.name
    })
  }
}
</script>

<style scoped>
.header {
  color: #fff;
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
}

.headBox {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-left {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.head-right {
  width: 350px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>