import request from '../request/request.js'
export const ImageUpload = (data) => {
    return request({
        url: `userFile/uploadImg`,
        method: "POST",
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export const SenceImageUpload = (data) => {
    return request({
        url: `scene/uploadImg`,
        method: "POST",
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
// 登录
export const Login = (data) => {
    return request({
        url: `login/codeLogin`,
        method: "POST",
        data
    })
}
// 免登录
export const exemptLogin = (data) => {
    return request({
        url: `login/ceshismsLogin`,
        method: "POST",
        data
    })
}
// 修改用户信息
export const AmendUser = (data) => {
    return request({
        url: `user/addUser`,
        method: "POST",
        data
    })
}
// 获取作品列表
export const GetList = (data) => {
    return request({
        url: `userFile/page`,
        method: "POST",
        data
    })
}
// 作品更新
export const UpdateExhibition = (data) => {
    return request({
        url: `composition/updateComposition`,
        method: "PUT",
        data
    })
}
// 批量上传
export const uploadFiles = (data) => {
    return request({
        url: `userFile/uploadFiles`,
        method: "POST",
        data
    })
}
// 作品列表分页
export const DataList = (data) => {
    return request({
        url: `composition/page`,
        method: "POST",
        data
    })
}
// 场景缩略图上传
export const UploadScenePicture = (data) => {
    return request({
        url: `composition/uploadImg`,
        method: "POST",
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
//  场景新建
export const AddScene = (data) => {
    return request({
        url: `composition/addComposition`,
        method: "POST",
        data
    })
}
export const Addroom = (data) => {
    return request({
        url: `room/addRoom`,
        method: "POST",
        data
    })
}
// 场景删除
export const DelScene = (data) => {
    // console.log(data);
    return request({
        url: `composition/delComposition`,
        method: "POST",
        data
    })
}
// 生成声网所需的token
export function productionToken(data) {
    return request({
        url: `shengwang/getSWRtcToken?channelName=${data}`,
        method: 'POST',
    })
}
// 生成TRTC所需的token
export function TRTCToken(data) {
    return request({
        url: `tencentyun/getGenerateUserSig?channelName=${data}`,
        method: 'POST',
    })
}
// 模板列表
export function TemplateList(data) {
    return request({
        url: `scene/sceneList`,
        method: 'POST',
        data
    })
}
// 上传模板
export function SenceZipUp(data) {
    return request({
        url: `scene/uploadZipFile`,
        method: 'POST',
        data,
        timeout:2400000,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
// 添加场景数据
export function Sencedata(data) {
    return request({
        url: `scene/addScene`,
        method: 'POST',
        data,
    })
}
