<template>
    <div class="right">
        <!-- <div class="righthead">
            <div style="width:240px;margin: 0 auto;">
                <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </div> -->
        <div class="right-context" style="width:260px">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="图片" name="first">
                    <!-- <ImgData @ShowPopBox="ShowPopBox"></ImgData> -->
                    <ImgData></ImgData>
                </el-tab-pane>
                <el-tab-pane label="视频" name="second">
                    <VidioData @ShowPopBox="ShowPopBox"></VidioData>
                </el-tab-pane>
                <!-- <el-tab-pane label="音频" name="third">
                    <MusicData></MusicData>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>
  
<script>
import ImgData from "../components/upload/imgupload.vue";
import VidioData from "../components/upload/vidioupload.vue";
import MusicData from "../components/upload/musicupload.vue";
export default {
    components: {
        ImgData,
        VidioData,
        MusicData,
    },
    data() {
        return {
            input1: '',
            input2: '',
            input3: '',
            select: '',
            fileList: [],
            activeName: 'first',
            centerDialogVisible: false,
            UploadBtn: false,
            ruleForm: {
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        // 切换
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        ShowPopBox() {
            this.centerDialogVisible = true
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.centerDialogVisible = false
                    this.UploadBtn = true
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    
    },
    mounted() {

    }
}
</script>
  
<style scoped>
.right {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10000;
}

.avatar-uploader-icon {
    border: 1px dashed #d9d9d9 !important;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px !important;
    position: relative !important;
    overflow: hidden !important;
}

.avatar-uploader .el-upload:hover {
    border: 1px dashed #d9d9d9 !important;
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 200px;
    height: 200px;
    display: block;
}

::v-deep .el-tabs__item {
    color: #fff;

}

.right-context {
    /* padding: 10px; */
}
::v-deep .el-tabs__nav-scroll{
    width: 100%;
    padding: 0 65px;
}
::v-deep .el-progress-circle {
    width: 120px;
    height: 120px;
}
</style>