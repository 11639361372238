export function setStorage(tokenKey, token) { 
    return localStorage.setItem(tokenKey, token)
}

export function getStorage(tokenKey) {	
    return localStorage.getItem(tokenKey)
}

export function removeToken(tokenKey) { 
    return localStorage.removeItem(tokenKey)
}

export function setsessionStorage(tokenKey, token) { 
    return sessionStorage.setItem(tokenKey, token)
}
export function getsessionStorage(tokenKey) { 
    return sessionStorage.getItem(tokenKey)
}

// unescape() 解码

