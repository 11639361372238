<template>
    <div class="LoginBox">
        <div class="LoginContext">
            <el-form :model="numberValidateForm" :rules="rules" ref="numberValidateForm" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="账号" prop="account">
                    <el-input v-model.number="numberValidateForm.account" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="numberValidateForm.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('numberValidateForm')">登录</el-button>
                    <!-- <el-button @click="resetForm('numberValidateForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { Login ,exemptLogin } from "../../utils/api/http.js";
import { setStorage } from '../../utils/token/token';
import { GetUrl } from "../../utils/utils.js"
export default {
    data() {
        return {
            numberValidateForm: {
                account: '',
                password: '',
                roomId: null,
                SecneId:null,
            },
            rules: {
                account: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { type: 'number', message: '请输入正确的账号', trigger: ['blur', 'change'] }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            }
        }
    },
    mounted() {
        this.roomId = GetUrl('roomId')
        this.SecneId = GetUrl('SecneId')
        // console.log(this.roomId);
        // console.log(this.SecneId);
        // console.log('-----------------------------+++++++++++++++++++++---------------------------------------');
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = {
                        phone: this.numberValidateForm.account,
                        code: this.numberValidateForm.password,
                    }
                    exemptLogin(data).then(res => {
                        if (res.code == 200) {
                            setStorage('token', res.token)
                            setStorage('UserId', res.user.id)
                            setStorage('Sex', res.user.sex)
                            setStorage('Garment', res.user.garment)
                            setStorage('UserName', res.user.name)
                            setStorage('isFirst', res.user.isFirst)
                            if (!this.roomId && !this.SecneId) {
                                if (res.user.isFirst == 'true') {
                                    // console.log('选择校色');
                                    this.$router.push({
                                        path: '/Role',
                                        query:{
                                            roomId:this.roomId,
                                            SecneId:this.SecneId
                                        }
                                    })
                                }
                                else {
                                    this.$router.push({
                                        path: '/Index',
                                        query:{
                                            roomId:this.roomId,
                                            SecneId:this.SecneId
                                        }
                                    })
                                }
                            } else {
                                setStorage('roomId',this.roomId)
                                if (res.user.isFirst == 'true') {
                                    // console.log(this.roomId);
                                    this.$router.push({
                                        path: '/Role',
                                        query:{
                                            roomId:this.roomId,
                                            SecneId:this.SecneId
                                        }
                                    })
                                } else {
                                    this.$router.push({
                                        path: '/Preview',
                                        query:{
                                            roomId:this.roomId,
                                            SecneId:this.SecneId
                                        }
                                    })
                                }
                            }
                        }
                    })
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // resetForm(formName) {
        //     this.$refs[formName].resetFields();
        // }
    }
}
</script>

<style scoped>
:root{
    --par:0;
}
.LoginBox {
    width: 100%;
}

.LoginContext {
    margin: 60px auto 0;
    width: 25%;
    min-width: 360px;
}
</style>