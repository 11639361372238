<template>
  <div class="left">
    <ul class="leftBox">
      <li  :class="color(index)" v-for="(item, index) in DataList" :key="index"  @click="ListBtn(item, index)">
       <span> 【{{ item.name }}】</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
      list: [
        { name: '列表1' },
        { name: '列表1' },
        { name: '列表1' },
        { name: '列表1' },
        { name: '列表1' },
        { name: '列表1' },
      ],
      DataList: null,
    }
  },
  created() {
    // console.log(DataList);
  },
  methods: {
    // 获取数据
    GetList(data) {
      this.DataList = data
    },
    // 点击列表跳转对应场景热点
    ListBtn(val, index) {
      globalVars.app.selectComponentFromList(val.id);
      this.$store.state.leftactive = index;
    },
    // 选中热点添加背景`1234567890-
    color(index) {
      if (this.$store.state.leftactive === index) {
        return 'active';
      }
    }
  }
}
</script>

<style scoped>
.left {
  color: #fff;
  width: 100%;
  height: 100%;
  /* background: #000; */
}

.leftBox {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.leftBox li {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  border-bottom: 2px solid #ccc;
}

.leftBox li:hover {
  background: #ccc;
  color: rgb(34, 34, 34);
}

::-webkit-scrollbar {
  width: 6px;
}
.active {
  background: #ccc;
  color: #000;
}
</style>