<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  data(){
      return{
        
      }
  },
  mounted() {
    var str = navigator.userAgent
    var ipad = str.match(/(iPad).*OS\s([\d_]+)/)
    var isIphone = !ipad && str.match(/(iPhone\sOS)\s([\d_]+)/)
    var isAndroid = str.match(/(Android)\s+([\d.]+)/)
    // 或者单独判断iphone或android
    if (isIphone) {
      this.$router.replace('/error')
    } else if (isAndroid) {
      this.$router.replace('/error')
    }
  },

}
</script>
<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
}
</style>
