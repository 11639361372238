<template>
  <div>
    <!-- <div class="top">
      <div class="topText">2023优秀毕业作品展</div>
    </div> -->
    <div class="Nav_container">
      <div class="center" id="wed3d"></div>
      <div
        style="
          width: 100vw;
          height: 10vh;
          position: absolute;
          z-index: 1000;
          top: 0;
        "
      >
        <div class="top-center">2023优秀毕业作品展</div>
      </div>
      <!-- 左侧菜单 -->
      <!-- <div v-show="LeftNav" class="navbox"> -->

      <div class="navbox">
        <!-- <img v-if="false" class="ReturnBtn" src="../../assets/metting/hys02.png" alt=""> -->
        <!-- <el-button v-if="CloseBtnVal" @click="CloseBtn()" style="margin-left: 60px;">取消</el-button> -->
        <div
          style="
            width: 5vw;
            height: 5vw;
            /* position: absolute; */
            margin-left: 10%;
            left: -2%;
            cursor: pointer;
          "
          :class="leftshow == 1 ? 'nav-a' : 'nav-a1'"
          @click="leftnav(1)"
        ></div>
        <div
          style="
            width: 5vw;
            height: 5vw;
            /* position: absolute; */
            margin-left: 10%;
            left: -2%;
            cursor: pointer;
          "
          :class="leftshow == 2 ? 'nav-b' : 'nav-b1'"
          @click="leftnav(2)"
        ></div>
        <!-- <div
          style="
            width: 90%;
            height: 10%;
            position: absolute;
            margin-left: 10%;
            left: -2%;
            cursor: pointer;
          "
          :class="leftshow == 3 ? 'nav-c' : 'nav-c1'"
          @click="leftnav(3)"
        ></div>
        <div
          style="
            width: 90%;
            height: 10%;
            position: absolute;
            cursor: pointer;
            margin-left: 10%;
            left: -2%;
          "
          :class="leftshow == 4 ? 'nav-d' : 'nav-d1'"
          @click="leftnav(4)"
        ></div> -->
      </div>
      <!--------------- 左侧菜单对应显示框 -->
      <!--共享屏幕  -->
      <!-- <div v-show="localplayer" :class="Pswitch == true ? 'Fullscreen' : 'screen'" id="local-player" v-drag>
            <div class="Pswitch" @click.stop="screenFull">
            </div>
        </div> -->
      <!--共享屏幕  -->
      <div v-show="localplayer" class="remote_stream" id="remote_stream" v-drag>
        <div class="Pswitch" @click.stop="screenFull"></div>
      </div>
      <!-- <div id="remote_stream" class="remote_stream"></div> -->
      <div v-if="leftshow == 1" class="content">
        <!-- 关闭按钮 -->
        <div class="MeetCloseBtn" @click="MeetCloseBtn(5)"></div>
        <div class="sharelink">
          <h1>展厅说明</h1>
          <p>
            动漫主体博物馆从动漫游戏艺术、科普教育、科技融合等多维度建立近现代国际动漫发展史料、艺术档案、理论文集
            等重要文物文献,积极推进动漫博物馆成为聚展览、收藏、研究和科普教育等于一身的国际性展示交流平台。
          </p>
          <!-- <div class="CopyBtn">
            <el-button @click="CopyBtn" type="info">点击复制链接</el-button>
          </div> -->
          <!-- <el-button size="small" icon="el-icon-document-copy" v-clipboard:copy="url" v-clipboard:success="onCopy"
                            v-clipboard:error="onError">
                        </el-button> -->
        </div>
      </div>
      <!-- 分享邀请 -->
      <div v-if="leftshow == 2" class="invitation">
        <!-- 关闭按钮 -->
        <div class="MeetCloseBtn" @click="MeetCloseBtn(5)"></div>
        <div class="sharelink">
          <div class="text">
            <p style="margin-left: 1.25rem">
              wasd为上下左右移动鼠标移动方向,鼠标点击展品可以互动
            </p>
          </div>
          <div class="wasd"></div>
          <!-- <span> {{ url }}</span> -->
          <!-- <div class="CopyBtn">
            <el-button @click="CopyBtn" type="info">点击复制链接</el-button>
          </div> -->
          <!-- <el-button size="small" icon="el-icon-document-copy" v-clipboard:copy="url" v-clipboard:success="onCopy"
                            v-clipboard:error="onError">
                        </el-button> -->
        </div>
      </div>
      <!-- 人员管理 -->
      <div v-if="leftshow == 3" class="personnel">
        <!-- title头部 -->
        <div class="MeetTitle"></div>
        <!-- 关闭按钮 -->
        <div class="MeetCloseBtn" @click="MeetCloseBtn(5)"></div>
        <!-- 人员管理内容 -->
        <div class="search">
          <el-input v-model.trim="inputval" placeholder="请输入内容"></el-input>
          <div class="SelectBtn" @click="SearchBtn"></div>
        </div>
        <div class="segmentation"></div>
        <!-- 人物数据 -->
        <div class="headertitle">
          <span class="nav_a"></span>
          <span class="nav_b">名字</span>
          <span class="nav_c">职位</span>
          <span class="nav_d">权限管理</span>
          <span class="nav_e">特殊权限</span>
          <span class="nav_f">基础权限</span>
        </div>
        <div style="height: 320px; overflow-y: scroll">
          <div class="popdata" v-for="(item, index) in AdminListB" :key="index">
            <span class="nav_a">{{ index + 1 }}</span>
            <span :class="item.OneSelf ? 'OneselfColor' : 'nav_b'">{{
              decodeURIComponent(item.displayName)
            }}</span>
            <span class="nav_c">{{ decodeURI(item.title) }}</span>
            <span class="nav_d"
              >管理<el-switch
                v-model="item.admin"
                :disabled="item.OneSelf"
                @change="AdminListBtn(item, index)"
                v-throttle="800"
              >
              </el-switch
            ></span>
            <span class="nav_e"
              >演讲<el-switch
                v-model="item.lecture"
                @change="lectureBtn(item)"
                v-throttle="1000"
              >
              </el-switch
            ></span>
            <span class="nav_f"
              >发言
              <el-switch
                v-model="item.speak"
                :disabled="!item.lecture"
                @change="SpeakBtn(item)"
                v-throttle="800"
              >
              </el-switch
              >动作<el-switch
                v-model="item.action"
                :disabled="true"
                @change="SmovementBtn(item)"
                v-throttle="800"
              >
              </el-switch
            ></span>
            <span class="nav_g" v-if="false"></span>
          </div>
        </div>
        <!-- 底部 -->
        <div class="footcontext">

          <!-- <div class="footleft">
                            <span class="foot_a">全场禁言</span>
                            <span :class="AllbannedValue == true ? 'foot_ba' : 'foot_bA'"></span>
                            <span class="foot_c"><span>开</span>
                                <el-switch :disabled="true" v-model="AllbannedValue" @change="Allbanned">
                                </el-switch><span>关</span>
                            </span>
                        </div>
                        <div class="footright">
                            <span class="foot_a">全场禁止动作</span>
                            <span :class="AllbannedActionValue == true ? 'foot_b' : 'foot_bb'"></span>
                            <span class="foot_c"><span>开</span>
                                <el-switch :disabled="true" v-model="AllbannedActionValue" @change="AllbannedAction">
                                </el-switch><span>关</span>
                            </span>
                        </div> -->
        </div>
      </div>
      <!-- 会议设置 -->
      <div v-if="leftshow == 4" class="meetingset">
        <!-- title头部 -->
        <div class="MeetTitle"></div>
        <!-- 关闭按钮 -->
        <div class="MeetCloseBtn" @click="MeetCloseBtn(5)"></div>
        <!-- 会议设置内容 -->
        <div class="MeetContent">
          <!-- 昵称显示 -->
          <!-- <div class="nameshow">
                            <span>昵称显示</span>
                            <div class="meetswich">
                                <span>开</span>
                                <el-switch v-model="nameshowval" @change="Namealter" v-throttle="800"> </el-switch>
                                <span>关</span>
                            </div>
                        </div> -->
          <!-- 摄像头显示 -->
          <div class="camerashow">
            <span>摄像头显示</span>
            <div class="meetswich">
              <span>开</span>
              <!-- <el-switch v-model="camerashowval" :disabled="true" @change="cameraShow"> </el-switch> -->
              <span>关</span>
            </div>
          </div>
          <!-- 发言方式 -->
          <div class="speakshow">
            <span>发言方式</span>
            <div class="meetswich">
              <!-- <el-radio-group v-model="radio" @change="speakstyle">
                                    <el-radio :disabled="true" label="1">{{ "" }}</el-radio>
                                    <span>申请发言</span>
                                    <el-radio :disabled="true" label="0">{{ "" }}</el-radio>
                                </el-radio-group> -->
              <span>自由发言</span>
            </div>
          </div>
          <!-- 允许参会者参加 -->
          <div class="allowshow">
            <span>允许参会者参加</span>
            <div class="meetswich">
              <span>开</span>
              <!-- <el-switch :disabled="true" v-model="joinval" @change="JoinBtn"> </el-switch> -->
              <span>关</span>
            </div>
          </div>
          <!-- 会场默认背景 -->
          <div class="backshow">
            <span>会场屏幕默认背景</span>
            <div class="meetswich">
              <!-- <div class="backimg">
                                    <img width="150px" height="75px" :src="BackgroundImg" alt="" />
                                </div>
                                <el-upload style="width:100px" action="#" drag :auto-upload="false" accept="image/png"
                                    :file-list="fileList" :show-file-list="false" :onChange="pngChange"
                                    :before-upload="handleBeforeUpload" :limit="1000">
                                    <div class="upload"></div>
                                </el-upload> -->
            </div>
          </div>
          <!-- 结束会议 -->
          <!-- <div class="overBtn" @click="EndBtn" v-throttle="800"></div> -->
        </div>
      </div>
      <!-- 右下角设备管理 -->
      <div class="equipment-bottom">
                  <div style="width:200px;height:100px;color: #c8e1db;float:right;text-align:center;line-height:100px;z-index:99">
            吉动云展三维媒体互动平台
          </div>
      <!-- <div class="equipmentbox">
        <span v-if="false" class="member"></span>
      <!-- <span v-show="!forbidShow.show" class="expressionforbid"></span> -->
      <span
          :class="projection == true ? 'expression' : 'expressionclose'"
          @click="Projection"
        ></span>
        <span class="microphone"></span>
        <span
          v-show="speakVal"
          :class="microphone == true ? 'vidicon' : 'vidiconclose'"
          @click="microphoneBtn"
        ></span>
        <span v-show="!speakVal" class="StopSpaak" @click="spackBtn"></span>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link" style="display: block; height: 100%">
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
    </div>
      <!-- 选择分辨率 -->
      <el-dialog
        title="选择分辨率和清晰度"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form :model="form">
          <el-form-item label="帧数" :label-width="'120px'">
            <el-select v-model="form.frame" placeholder="请选择帧数">
              <el-option label="15" :value="15"></el-option>
              <el-option label="20" :value="20"></el-option>
              <el-option label="25" :value="25"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分辨率" :label-width="'120px'">
            <el-select v-model="form.ratio" placeholder="请选择分辨率">
              <el-option label="540P" :value="540"></el-option>
              <el-option label="720P" :value="720"></el-option>
              <el-option label="1080P" :value="1080"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模式" :label-width="'120px'">
            <el-select v-model="form.pattern" placeholder="请选择模式">
              <el-option label="画质优先" :value="false"></el-option>
              <el-option label="流畅优先" :value="true"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="SavedistinctBtn">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
let Testdata = null;
let viewer = null;
// let modelType=null
// import Left from '../components/left.vue';
import screenfull from "screenfull";
import { Copy, Getitem, Setitem, GetUrl } from "../../utils/utils.js";
import { getStorage, setStorage } from "../../utils/token/token.js";
import { productionToken, DataList, TRTCToken } from "../../utils/api/http.js";
import TRTC from "trtc-js-sdk";
export default {
  data() {
    return {
      // -----------------------------------------------------------------TRTC  -start
      // 判断浏览器是否支持TRTC
      isSupport: false, // 浏览器是否支持
      isAtRoom: false, // 是否已进房
      isShare: false, // 是否正在分享

      formData: {
        userId: getStorage("UserId"),
        roomId: null,
      },
      sdkAppId: "",
      userSig: "",
      client: null,
      localStream: null,
      CloseBtnVal: true,
      // -----------------------------------------------------------------TRTC  - end
      user: {
        // face: getStorage('face'),
        garment: getStorage("Garment"),
        // hair: getStorage('hair'),
        id: getStorage("UserId"),
        // information: getStorage('information'),
        // isFirst: getStorage('isFirst'),
        // jacket: getStorage('jacket'),
        name: getStorage("UserName"),
        // phone: getStorage('phone'),
        // position: getStorage('position'),
        // roomFirst: getStorage('roomFirst'),
        sex: getStorage("sex"),
      },
      // ListData: [],
      sceneData: null,
      modelType: null,
      projection: false,
      speakVal: false,
      fileList: [],
      url: "",
      // 全场禁言
      AllbannedValue: false,
      // 全场禁止动作
      AllbannedActionValue: false,
      value: "",
      roomID: "",
      // 麦克风开关
      microphone: false,
      isshow: false,
      // 控制右侧伸缩栏
      swichshow: 1,
      // 控制左侧菜单选项
      leftshow: 5,
      // 投屏显示开关
      localplayer: false,
      // 左侧导航栏
      LeftNav: false,
      //昵称显示
      nameshowval: "",
      // 摄像头显示
      camerashowval: "",
      //发言方式
      radio: "1",
      // 允许参加
      joinval: "",
      // 人员列表搜索框数据
      inputval: "",
      // 会议全屏开关
      Pswitch: false,
      // 会议屏幕背景
      BackgroundImg: "",
      // 人员管理数据列表
      AdminList: [],
      AdminListB: [],
      // 权限人员列表
      Presentation: null,
      // 拥有演讲权限的列表
      openspeak: [],
      // 拥有管理权限的列表
      openadmin: [],
      // 判断有没有站到演讲台
      podium: false,
      // // 测试
      // podium: true,
      // 分辨率选择框
      dialogFormVisible: false,
      // 动态目录路径
      filePath: "",
      form: {
        frame: 20,
        ratio: 720,
        pattern: false,
      },
    };
  },
  created() {
    // this.sceneData=JSON.parse(window.localStorage.getItem('UrlDAta'))
    this.modelType = window.localStorage.getItem("SecneType");
    // this.filePath = window.localStorage.getItem('filePath');
    // console.log(this.filePath);
    this.roomID = getStorage("roomId");
    // 检测浏览器是否兼容 TRTC
    if (!TRTC.checkSystemRequirements()) {
      // this.$alert("浏览器不兼容 TRTC，请安装最新版Chrome浏览器");
      return false;
    }
    // 检测浏览器是否支持屏幕分享
    if (!TRTC.isScreenShareSupported()) {
      // this.$alert("浏览器不支持屏幕分享，请安装最新版Chrome浏览器");
      return false;
    }
    this.isSupport = true;
  },
  mounted() {
    var str = navigator.userAgent
    var ipad = str.match(/(iPad).*OS\s([\d_]+)/)
    var isIphone = !ipad && str.match(/(iPhone\sOS)\s([\d_]+)/)
    var isAndroid = str.match(/(Android)\s+([\d.]+)/)
    // 或者单独判断iphone或android
    if (isIphone) {
      this.$router.replace('/error')
    } else if (isAndroid) {
      this.$router.replace('/error')
    }
    this.url = window.location.href;
    // 获取Blob
    window.EDITOR = false;
    window.globalVars = {};
    const app = new HotSpotEditor.HotSpotEditorApplication(
      $("<div id='renderArea' class=\"render-area-landscape\"></div>").appendTo(
        "#wed3d"
      )
    );
    const RoomID = GetUrl("roomId");
    const SecneId = GetUrl("SecneId");
    // const RoomID = window.location.href.slice(window.location.href.indexOf('&roomId=') + 8, window.location.href.length + 26);
    // const SecneId = window.location.href.slice(window.location.href.indexOf('&SecneId=') + 10, window.location.href.length - 27);
    // const exempt = window.location.href.slice(window.location.href.indexOf('&a=') + 3, window.location.href.indexOf('&a=') + 8);
    // 判断当前是不是通过分享链接进入场景的
    if (window.location.href.indexOf("?") != -1) {
      if (SecneId) {
        // var SecneID=paramRequest['SecneId']
        let data = {
          composition: {
            userId: "",
            phone: "",
            compositionId: SecneId,
          },
          pageNum: 1,
          pageSize: 1,
        };
        DataList(data).then((res) => {
          console.log(res, "新数据新数据新数据新数据新数据新数据新数据新数据");
          setStorage("UrlDAta", res.data.list[0].modelInformation);
          setStorage("SecneId", res.data.list[0].compositionId);
          setStorage("SecneType", res.data.list[0].modelType);
          setStorage("roomId", res.data.list[0].roomId);
          this.filePath = res.data.list[0].filePath;
          const projConfig = {
            // projectFolder: "../data/",
            projectFolder: this.filePath + "/",
            // mainScene: this.sceneData,
            sceneData: JSON.parse(res.data.list[0].modelInformation),
            // mode: "edit",
            mmoOptions: {
              playerName: "Anonymous", //与会者名称（必选）
              gender: getStorage("Sex"), //与会者性别
              spaceID: "1", //场地ID
              roomID: res.data.list[0].compositionId, //房间ID
              avatarID: getStorage("Sex"), //与会者性别
              userName: getStorage("UserName"), //网络认证用户名
              // password?: string,   //网络认证密码

              meta: {
                id: getStorage("UserId"),
                title: `BigBoss`,
                // displayName: btoa(encodeURIComponent(getStorage("UserName"))),
                // displayName: btoa(encodeURIComponent("游客")),
                avatarScaler: res.data.list[0].modelType == 1 ? 1.8 : 1,
                hairColor: getStorage("Garment"),
                shirtColor: getStorage("Garment"),
                trousersColor: getStorage("Garment"),
              },
            },
          };
          // debugger
          const Viewer = app.loadProject(projConfig).then((compMap) => {
            console.log(
              `Project loaded , editable components map is ${compMap}--------------------------加入房间`
            );
            if (globalVars.isMMOMode) {
              if (res.data.list[0].modelType == 1)
                (globalVars.player.parent.position.y = 30.8),
                  (globalVars.player.orbitControl.maxDistance += 3.5),
                  (ThirdPersonPlayerController.CameraMaxDistance += 3.5);
              if (res.data.list[0].modelType == 2)
                (globalVars.player.parent.position.y = 0),
                  (globalVars.player.orbitControl.maxDistance += 0.5),
                  (ThirdPersonPlayerController.CameraMaxDistance += 0.5);
              globalVars.mmoManager = new MMOManager(projConfig.mmoOptions);
              globalVars.mmoManager.setupNetwork();
              globalVars.player.activeSightDetection();
              globalVars.player.sightBlockerInfo = {
                collider:
                  "assets/Models/GalaxyMuseumV3/Collider_GalaxyMuseum.glb",
                position: [0, 0, 0],
                scale: [1, 1, 1],
              };
            }
            globalVars.mmoManager.roomMgr = roomMgr;
          });
          viewer = Viewer;
          viewer.roomMgr = roomMgr;
        });
      }
    } else {
      const projConfig = {
        projectFolder: "../data/",
        // mainScene: this.sceneData,
        sceneData: JSON.parse(Getitem("UrlDAta")),
        // mode: "edit",
        mmoOptions: {
          playerName: "Anonymous", //与会者名称（必选）
          gender: getStorage("Sex"), //与会者性别
          spaceID: "1", //场地ID
          roomID: window.localStorage.getItem("SecneId"), //房间ID
          avatarID: getStorage("Sex"), //与会者性别
          userName: getStorage("UserName"), //网络认证用户名
          // password?: string,   //网络认证密码

          meta: {
            id: getStorage("UserId"),
            title: `BigBoss`,
            displayName: btoa(encodeURIComponent(getStorage("UserName"))),
            avatarScaler: this.modelType == 1 ? 1.8 : 1,
            hairColor: getStorage("Garment"),
            shirtColor: getStorage("Garment"),
            trousersColor: getStorage("Garment"),
          },
        },
      };
      // const Viewer = app.loadProject(projConfig).then((compMap) => {
      //     console.log(`Project loaded , editable components map is ${compMap}--------------------------加入房间`);
      //     if (globalVars.isMMOMode) {
      //         if (this.modelType == 1) globalVars.player.parent.position.y = 30.8, globalVars.player.orbitControl.maxDistance += 3.5, ThirdPersonPlayerController.CameraMaxDistance += 3.5;
      //         if (this.modelType == 2) globalVars.player.parent.position.y = 0, globalVars.player.orbitControl.maxDistance += 0.5, ThirdPersonPlayerController.CameraMaxDistance += 0.5;
      //         globalVars.mmoManager = new MMOManager(projConfig.mmoOptions);
      //         globalVars.mmoManager.setupNetwork();
      //         globalVars.player.activeSightDetection();
      //         globalVars.player.sightBlockerInfo = {
      //             collider: "assets/Models/GalaxyMuseumV3/Collider_GalaxyMuseum.glb",
      //             position: [0, 0, 0],
      //             scale: [1, 1, 1],
      //         };
      //     }
      //     globalVars.mmoManager.roomMgr = roomMgr;
      // });
      // viewer = Viewer;
      // viewer.roomMgr = roomMgr;
    }
    // const loginTime = new Date();
    // const randomUid = `${loginTime.getMinutes()}-${loginTime.getSeconds()}`;

    // globalVars.mmoManager.roomMgr=roomMgr;
    // 调用监听房间数据变化
    this.InitMyPermission();
    // 初始化房间数据
    roomMgr
      .Authorization(this.roomID, this.user.id, this.user.name)
      .then((res) => {
        // console.log(globalVars.mmoManager.getOnlineUsers());
        console.log(
          res,
          "--------------------------------------------------------------------------------------------------------"
        );
        this.joinval = res.informations.customization.allowEnter;
        this.nameshowval = res.informations.customization.nameShow;
        this.radio = res.informations.customization.speakMode;
        this.camerashowval = res.informations.customization.cameraShow;
        // this.BackgroundImg = res.informations.customization.backgroundUrl;
        // this.BackgroundImg = res.logoImage;
        this.AllbannedValue = res.informations.customization.AllforbidSpeak;
        this.AllbannedActionValue =
          res.informations.customization.AllforbidAction;
        // 判断是不是管理员从而隐藏左侧导航栏
        if (res.informations.adminTable[this.user.id] != undefined) {
          this.LeftNav = true;
          // this.projection = true;
          // this.speakVal = true;
        }
        // app.viewer.vrSpace.SetMeshTexture('pingmu', this.BackgroundImg);
        // app.viewer.vrSpace.SetMeshTexture('fenping1', this.BackgroundImg);
        // app.viewer.vrSpace.SetMeshTexture('fenping2', this.BackgroundImg);
        this.Presentation = res.informations.permissionList;
        roomMgr.UpdateRoomInfo(this.user.id);
      });

    // //  初始化加入声网房间
    // this.JoinAgRoom((result) => {
    //     if (result) {
    roomMgr.RegisterRoomInfoChangedEvent(this.RoomAlter);
    //     }
    //     else {
    //         roomMgr.RegisterRoomInfoChangedEvent(this.RoomAlter);
    //         return;
    //     }
    // })
    // 绑定投屏的回调
    // liveStreaming.bindStopShareScreenCB(this.Cancelscreen);
    // liveStreaming.bindStartShareScreenCB(this.SuccessScreen);

    // 腾讯TRTC-------------------------start
    // 进入房间
    if (this.isSupport) {
      this.getUserSig();
    } else {
      // this.$alert("浏览器环境不支持，请安装最新版Chrome浏览器后重试");
    }

    // 腾讯TRTC-------------------------end
  },
  methods: {
    CopyBtn() {
      let ShareUrl = window.location.href;
      Copy(ShareUrl);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 左侧菜单栏切换
    leftnav(val) {
      // 当val == 1点击共享屏幕时
      // if (val == 1) {
      //   this.Projection();
      // }
      if (val == 3) {
        this.judge();
        console.log(this.AdminList);
        return;
      }
      this.leftshow = val;
    },
    // 右下角人员管理
    Projection() {
      this.createStream();
      // if (this.projection) {
      //     this.dialogFormVisible = true;
      //     // if (this.podium) {
      //     //     this.dialogFormVisible = true;
      //     // } else {
      //     //     alert('已拥有演讲权限，请走到讲台开始发言');
      //     // }
      // } else {
      //     alert('暂无权限');
      // }
    },
    // 清晰度选择确定
    SavedistinctBtn() {
      // console.log(this.form);
      // this.dialogFormVisible = false;
      // liveStreaming.setEncoderConfig(Number(this.form.frame), Number(this.form.ratio), this.form.pattern);
      // let promise = liveStreaming.startShareScreen();
      // promise.then(() => {
      //     console.log('投屏成功');
      //     this.localplayer = true;
      // })
      //     .catch((err) => {
      //         console.log(err);
      //     });
    },
    // 拿取列表i
    judge() {
      this.AdminList = [];
      console.log(globalVars.mmoManager.getOnlineUsers());
      let ListData = null;
      ListData = JSON.parse(
        JSON.stringify(globalVars.mmoManager.getOnlineUsers())
      );
      for (let i = 0; i < ListData.length; i++) {
        ListData[i].displayName = window.atob(ListData[i].displayName);
        if (ListData[i].isOnline) {
          this.AdminList.push(ListData[i]);
        }
      }
      for (let i = 0; i < this.AdminList.length; i++) {
        pHelper.mask =
          roomMgr.roomInfo.informations.permissionList[this.AdminList[i].id] ||
          0;
        // pHelper.mask = this.AdminList[i].id
        // this.$set(this.AdminList[i], 'speakBtn', true)
        this.$set(
          this.AdminList[i],
          "lecture",
          pHelper.hasPermission(NetworkService.RoomPermission.Presentation)
        );
        this.$set(
          this.AdminList[i],
          "speak",
          pHelper.hasPermission(NetworkService.RoomPermission.Speak)
        );
        this.$set(
          this.AdminList[i],
          "action",
          pHelper.hasPermission(NetworkService.RoomPermission.Action)
        );
        // console.log(pHelper.hasPermission(NetworkService.RoomPermission.Management));
        this.$set(
          this.AdminList[i],
          "admin",
          pHelper.hasPermission(NetworkService.RoomPermission.Management)
        );
        // 判断当前权限开启还是关闭
        if (this.user.id == this.AdminList[i].id) {
          this.AdminList[i].OneSelf = true;
          this.AdminList[i].admin = true;
        }
        this.AdminList[i].playerName = decodeURIComponent(
          this.AdminList[i].playerName
        );
        // if (this.AdminList[i].speak) {
        //   this.AdminList[i].speakBtn == false;
        // }
      }
      this.AdminListB = this.AdminList;
      this.leftshow = 3;
    },
    //人物管理设置
    AdminListBtn(val, index) {
      console.log(val);
      if (val.admin) {
        this.openadmin = [];
        for (let i = 0; i < this.AdminList.length; i++) {
          if (this.AdminList[i].admin == true) {
            this.openadmin.push(this.AdminList[i].id);
          }
        }

        if (this.openadmin.length >= 16) {
          this.$message.error("超出管理员人数上限！");
          val.admin = false;
          return false;
        }
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
        pHelper.setPermission(NetworkService.RoomPermission.Management);
        pHelper.setPermission(NetworkService.RoomPermission.Speak);
        pHelper.setPermission(NetworkService.RoomPermission.Presentation);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
        roomMgr.roomInfo.informations.adminTable[val.id] = 0;
        // pHelper.setPermission(NetworkService.RoomPermission.Presentation, false);
        // pHelper.setPermission(NetworkService.RoomPermission.Speak, false);
        // pHelper.setPermission(NetworkService.RoomPermission.Action, false);
        roomMgr.UpdateRoomInfo(this.user.id);
        val.lecture = true;
        val.speak = true;
        // val.action = true;
        // this.$set(val, 'speakBtn', true);
        console.log(this.LeftNav);
      } else {
        console.log("关闭");
        pHelper.setPermission(NetworkService.RoomPermission.Management, false);
        pHelper.setPermission(
          NetworkService.RoomPermission.Presentation,
          false
        );
        pHelper.setPermission(NetworkService.RoomPermission.Speak, false);
        pHelper.setPermission(NetworkService.RoomPermission.Action, false);
        val.lecture = false;
        val.speak = false;
        val.action = false;
        // this.$set(val, 'speakBtn', true);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
        delete roomMgr.roomInfo.informations.adminTable[val.id];
        roomMgr.UpdateRoomInfo(this.user.id);
        // Reflect.defineProperty(roomMgr.roomInfo.informations.adminTable,val.id);
      }

      // roomMgr.UpdateRoomInfo(this.user.id);
    },
    // 人物演讲权限设置
    lectureBtn(val) {
      // console.log(this.AdminList);
      pHelper.mask = roomMgr.roomInfo.informations.permissionList[val.id] || 0;
      if (val.lecture) {
        this.openspeak = [];
        for (let i = 0; i < this.AdminList.length; i++) {
          if (this.AdminList[i].lecture == true) {
            this.openspeak.push(this.AdminList[i].id);
          }
        }
        if (this.openspeak.length >= 16) {
          console.log(this.openspeak);
          this.$message.error("超出发言人数上限！");
          val.lecture = false;
          return false;
        }
        console.log("打开");
        pHelper.setPermission(NetworkService.RoomPermission.Presentation);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
        // val.speakBtn = false
      } else {
        console.log("关闭");
        pHelper.setPermission(
          NetworkService.RoomPermission.Presentation,
          false
        );
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
        val.speak = false;
        pHelper.setPermission(NetworkService.RoomPermission.Speak, false);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
        // val.speakBtn = true
      }
      roomMgr.UpdateRoomInfo(this.user.id);
    },
    // 人物发言权限设置
    SpeakBtn(val) {
      pHelper.mask = roomMgr.roomInfo.informations.permissionList[val.id] || 0;
      if (val.speak) {
        pHelper.setPermission(NetworkService.RoomPermission.Speak);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
      } else {
        pHelper.setPermission(NetworkService.RoomPermission.Speak, false);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
      }
      roomMgr.UpdateRoomInfo(this.user.id);
    },
    // 人物动作权限设置
    SmovementBtn(val) {
      pHelper.mask = roomMgr.roomInfo.informations.permissionList[val.id] || 0;
      if (val.speak) {
        pHelper.setPermission(NetworkService.RoomPermission.Action);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
      } else {
        pHelper.setPermission(NetworkService.RoomPermission.Action, false);
        roomMgr.roomInfo.informations.permissionList[val.id] = pHelper.mask;
      }
      0;
      roomMgr.UpdateRoomInfo(this.user.id);
    },
    // 初始化加入声网房间
    JoinAgRoom(callback) {
      // 生成声网所需Token
      productionToken(this.roomID)
        .then((res) => {
          // let promise = liveStreaming.join(this.roomID, res.data, this.user.id);
          // promise.then(() => {
          //     console.log('成功加入房间');
          //     for (let key in this.Presentation) {
          //         if (this.user.id == key) {
          //             pHelper.mask = this.Presentation[key]
          //             if (pHelper.hasPermission(NetworkService.RoomPermission.Presentation)) {
          //                 liveStreaming.setHost(true);
          //                 this.projection = true;
          //             } else {
          //                 liveStreaming.setHost(false);
          //                 this.projection = false;
          //             }
          //             if (pHelper.hasPermission(NetworkService.RoomPermission.Speak)) {
          //                 this.speakVal = true;
          //                 liveStreaming.setSpeaker(true)
          //             } else {
          //                 this.speakVal = false;
          //                 liveStreaming.setSpeaker(false)
          //             }
          //         }
          //     }
          //     callback(true);
          // })
          //     .catch((err) => {
          //         callback(false);
          //     });
        })
        .catch();
    },
    // 定义监听房间的数据
    InitMyPermission() {
      this.myCurrentPermission = {
        management: undefined,
        presentation: undefined,
        speak: undefined,
        action: undefined,
        backUrl: undefined,
        ShowName: undefined,
      };
    },
    // 退出登录
    handleCommand(command) {
      console.log(command);
      switch (command) {
        case "a":
          window.localStorage.clear();
          setTimeout(() => {
            this.$router.go(0);
          }, 300);
          // this.$router.push({
          //   path: '/',
          //   query: {
          //     roomid: this.$route.query.roomid,
          //     roomname: this.$route.query.roomname,
          //   }
          // })
          break;
      }
    },
    // 暂无发言权限
    spackBtn() {
      alert("暂无发言权限");
    },
    // 监听房间数据变化
    RoomAlter(prevRoomInfo, updatedRoomInfo) {
      console.log(updatedRoomInfo);
      pHelper.mask =
        updatedRoomInfo.informations.permissionList[this.user.id] || 0;
      let myNewPermission = {
        management: pHelper.hasPermission(
          NetworkService.RoomPermission.Management
        ),
        presentation: pHelper.hasPermission(
          NetworkService.RoomPermission.Presentation
        ),
        speak: pHelper.hasPermission(NetworkService.RoomPermission.Speak),
        action: pHelper.hasPermission(NetworkService.RoomPermission.Action),
        backUrl: updatedRoomInfo.informations.customization.backgroundUrl,
        ShowName: updatedRoomInfo.informations.customization.nameShow,
      };

      console.log("管理：" + myNewPermission.management);
      console.log("演讲：" + myNewPermission.presentation);
      console.log("发言：" + myNewPermission.speak);
      console.log("动作：" + myNewPermission.action);

      // 姓名显示隐藏
      // if (this.myCurrentPermission.ShowName == undefined || this.myCurrentPermission.ShowName != myNewPermission.ShowName) {
      //     app.viewer.setNameCardDisplay(updatedRoomInfo.informations.customization.nameShow)
      //     roomMgr.roomInfo.informations.customization.nameShow = updatedRoomInfo.informations.customization.nameShow
      //     this.nameshowval = updatedRoomInfo.informations.customization.nameShow
      //     // roomMgr.UpdateRoomInfo(this.user.id)
      // };
      // 背景图
      // if (this.myCurrentPermission.backUrl == undefined || this.myCurrentPermission.backUrl != myNewPermission.backUrl) {
      //     app.viewer.vrSpace.SetMeshTexture('pingmu', updatedRoomInfo.informations.customization.backgroundUrl)
      //     app.viewer.vrSpace.SetMeshTexture('fenping1', updatedRoomInfo.informations.customization.backgroundUrl)
      //     app.viewer.vrSpace.SetMeshTexture('fenping2', updatedRoomInfo.informations.customization.backgroundUrl)
      //     roomMgr.roomInfo.informations.customization.backgroundUrl = updatedRoomInfo.informations.customization.backgroundUrl
      //     // roomMgr.UpdateRoomInfo(this.user.id)
      // };
      // 管理
      if (
        this.myCurrentPermission.management == undefined ||
        this.myCurrentPermission.management != myNewPermission.management
      ) {
        this.myCurrentPermission.management = myNewPermission.management;
        if (this.myCurrentPermission.management) {
          this.LeftNav = true;
        } else {
          this.LeftNav = false;
        }
      }
      // 演讲
      if (
        this.myCurrentPermission.presentation == undefined ||
        this.myCurrentPermission.presentation != myNewPermission.presentation
      ) {
        this.myCurrentPermission.presentation = myNewPermission.presentation;
        // liveStreaming.setHost(true);
        // liveStreaming.setSpeaker(true);
        if (this.myCurrentPermission.presentation) {
          // liveStreaming.setHost(true);
          // liveStreaming.setSpeaker(true)
          this.projection = true;
          console.log(this.projection);
        } else {
          // liveStreaming.setHost(false);
          // liveStreaming.setSpeaker(false)
          this.projection = false;
        }
      }
      if (
        this.myCurrentPermission.speak == undefined ||
        this.myCurrentPermission.speak != myNewPermission.speak
      ) {
        this.myCurrentPermission.speak = myNewPermission.speak;
        if (this.myCurrentPermission.speak) {
          this.speakVal = true;
          // liveStreaming.setSpeaker(true)
        } else {
          this.speakVal = false;
          // liveStreaming.setSpeaker(false)
        }
      }

      if (
        this.myCurrentPermission.action == undefined ||
        this.myCurrentPermission.action != myNewPermission.action
      ) {
        this.myCurrentPermission.action = myNewPermission.action;
        if (this.myCurrentPermission.action) {
        } else {
        }
      }
    },
    //  投屏设置全屏
    screenFull() {
      //打印一下screenfull看看属性名称是不是enabled
      console.log(screenfull);
      if (!screenfull.enabled) {
        // 如果不允许进入全屏，发出不允许提示
        this.$message({
          message: "不支持全屏",
          type: "warning",
        });
        return;
      }
      screenfull.toggle(document.getElementById("local-player"));
    },
    // 成功分享屏幕之后的回调
    // SuccessScreen() {
    //     this.localplayer = true
    //     console.log('成功分享了全屏幕');
    // },
    // // 取消分享屏幕的回调
    // Cancelscreen() {
    //     this.localplayer = false
    //     console.log('取消了全屏幕');
    // },
    // 昵称显示
    Namealter(val) {
      //   roomMgr.roomInfo.informations.customization.nameShow = val
      //   roomMgr.UpdateRoomInfo(this.user.id)
    },
    // 会议设置弹窗关闭
    MeetCloseBtn(val) {
      this.leftshow = val;
    },
    // 麦克风开关
    microphoneBtn() {
      this.microphone = !this.microphone;
      if (this.microphone) {
        this.localStream.unmuteAudio();
      } else {
        this.localStream.muteAudio();
      }
    },
    // 搜索列表
    SearchBtn() {
      console.log(this.inputval, "搜索");
      let search = this.inputval;
      this.AdminListB = this.AdminList.filter(function (product) {
        console.log("过滤", product);
        let searchField = {
          displayName: decodeURIComponent(product.displayName),
        };
        console.log(searchField);
        return Object.keys(searchField).some(function (key) {
          console.log("key值", key);
          return String(product[key]).toLowerCase().indexOf(search) > -1;
        });
      });
    },

    // -----------------------------------------------------TRTC  start
    // 获取用户签名
    getUserSig() {
      const userId = getStorage("UserId");
      TRTCToken(userId).then((res) => {
        console.log(res);
        this.sdkAppId = 1400809336;
        this.userSig = res.data;
        this.createClient();
      });
    },
    // 关闭分享
    CloseBtn() {
      console.log(111111);
      this.localStream.close();
      this.client.unpublish(this.localStream).then(() => {
        // 取消发布本地流成功
        console.log("取消发布屏幕分享流成功");
        this.localStream = null;
        this.isShare = false;
        this.CloseBtnVal = true;
      });
    },
    // 创建屏幕分享客户端对象
    createClient() {
      const userId = getStorage("UserId");
      const sdkAppId = this.sdkAppId;
      const userSig = this.userSig;
      this.client = TRTC.createClient({
        useStringRoomId: true,
        mode: "live",
        sdkAppId,
        userId,
        userSig,
      });
      console.log("client客户端对象创建成功");
      this.subscribeStream();
      this.joinRoom();
    },
    // 创建音频通话对象
    // createAudio(){
    //     const userId = getStorage('UserId');
    //     const sdkAppId = this.sdkAppId;
    //     const userSig = this.userSig;
    //     this.client = TRTC.createClient({
    //         useStringRoomId: true,
    //         mode: 'rtc',
    //         sdkAppId,
    //         userId,
    //         userSig
    //     });
    //     console.log('client客户端对象创建成功');
    // },
    // 进房
    joinRoom() {
      // debugger
      // const roomId = Number(this.roomID);
      const roomId = this.roomID;
      this.client
        .join({ roomId, role: "anchor" })
        .then(() => {
          console.log("进房成功");
          this.isAtRoom = true;
          this.playStream();
        })
        .catch(() => {});
    },
    // 创建本地音视频流
    createStream() {
      // 创建屏幕分享流
      this.localStream = TRTC.createStream({ audio: true, screen: true });
      console.log(
        this.localStream,
        "------------------------------------------------------"
      );
      // 初始化屏幕分享流
      this.localStream
        .initialize()
        .then(() => {
          console.log("屏幕分享流初始化成功");
          // 发布屏幕分享流
          this.publishStream();
        })
        .catch(() => {});
    },
    // 发布本地音视频流
    publishStream() {
      this.client.publish(this.localStream).then(() => {
        console.log("发布屏幕分享流成功");
        this.isShare = true;
      });
    },
    // 订阅远端流
    subscribeStream() {
      this.client.on("stream-added", (event) => {
        const remoteStream = event.stream;
        console.log("远端流增加: " + remoteStream.getId());
        //订阅远端流
        this.client.subscribe(remoteStream);
      });
    },
    // 播放远端流
    playStream() {
      // debugger
      this.client.on("stream-subscribed", (event) => {
        this.localplayer = true;
        this.CloseBtnVal = true;
        const remoteStream = event.stream;
        // 远端流订阅成功，播放远端音视频流
        console.log("远端流订阅成功，播放远端音视频流", event);
        remoteStream.play("remote_stream");
      });
      this.client.on("stream-removed", (event) => {
        const remoteStream = event.stream;
        console.log(
          "remoteStream ID: " + remoteStream.getId() + " has been removed"
        );
        // 停止播放并删除相应<video>标签
        remoteStream.stop();
        document.getElementById("remote_stream").innerHTML = "";
        this.localplayer = false;
      });
    },
    // -----------------------------------------------------TRTC  end
  },
  beforeDestrory() {
    viewer = null;
  },
};
</script>

<style lang="scss" scoped>
//  @import url('../../assets/css/preview.scss');
.center {
  /* flex: 1; */
  z-index: 99;
  position: relative;
  width: 100%;
  height: 100vh;
}

// .CopyBtn {

// }
.top {
  width: 100vw;
  height: 10vh;
  position: relative;
  .topText {
    width: 65vw;
    height: 10vh;
    position: absolute;
    background: url("../../assets/metting/dbbiaoti.png") no-repeat;
    background-size: 100% 100%;
    top: 0;
    left: 50;
  }
  z-index: 1001;
}
.Nav_container {
  .top-center {
    margin-left: 30vw;
    margin-right: 30vw;
    line-height: 10vh;
    background: url("../../assets/metting/dbbiaoti.png");
    background-size: 100% 100%;
    text-align: center;
    font-size: 5vh;
    color: #c8e1db;
  }
  .navbox {
    height: 100%;
    width: 15%;
    // background: url("../../assets/metting/hys04.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    z-index: 1000;
    top: 20%;
    left: 0;

    .ReturnBtn {
      cursor: pointer;
      width: 50%;
      display: block;
      margin: 0 auto;
      height: auto;
      margin-top: 25%;
    }

    // .navA {

    // }
    .nav-a {
      // width: 6.9375rem;
      // height: 6.9375rem;
      // top: 27%;
      top: 33%;
      // background: url("../../assets/metting/hys08.png") no-repeat;
      background: url("../../assets/metting/jieshao-0.png") no-repeat;
      background-size: 100% 100%;
      // transition: all 0.5s;
    }

    .nav-a1 {
      // width: 6.9375rem;
      // height: 6.9375rem;
      // top: 27%;
      top: 33%;
      // background: url("../../assets/metting/hys07.png") no-repeat;
      background: url("../../assets/metting/jieshao-1.png") no-repeat;
      background-size: 100% 100%;
      // transition: all 0.5s;
    }

    .nav-b {
      // width: 6.9375rem;
      // height: 6.9375rem;
      // top: 41%;
      top: 33%;
      // background: url("../../assets/metting/hys10.png") no-repeat;
      background: url("../../assets/metting/bangzhu-0.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.5s;
    }

    .nav-b1 {
      // width: 6.9375rem;
      // height: 6.9375rem;
      // top: 41%;
      top: 33%;
      // background: url("../../assets/metting/hys09.png") no-repeat;
      background: url("../../assets/metting/bangzhu-1.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.5s;
    }

    .nav-c {
      top: 56%;
      // background: url("../../assets/metting/hys12.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.5s;
    }

    // .nav-c1 {
    //     top: 56%;
    //     background: url("../../assets/metting/hys11.png") no-repeat;
    //     background-size: 100% 100%;
    //     transition: all 0.5s;
    // }

    .nav-d {
      top: 70%;
      background: url("../../assets/metting/hys14.png") no-repeat;
      background-size: 100% 100%;
      transition: all 0.5s;
    }

    // .nav-d1 {
    //     top: 70%;
    //     background: url("../../assets/metting/hys13.png") no-repeat;
    //     background-size: 100% 100%;
    //     transition: all 0.5s;
    // }
  }
  .content {
    // width: 928px;
    // height: 580px;
    width: 50vw;
    height: 30vh;
    position: absolute;
    top: 35vh;
    left: 30vw;
    z-index: 200;
    transform: translate(-50%, -50%);
    // background: url("../../assets/metting/hys37.png") no-repeat;
    background: url("../../assets/metting/dbwenzi1.png") no-repeat;
    // background-size: 100% 100%;
    background-size: 100% 100%;
    // transition: all 0.5s;
    

    .sharelink {
      // width: 37.5rem;
      // height: 300px;
      width: 35vw;
      height: 10vh;
      font-size: 2vh;
      margin-top: 1.5625rem;
      margin-left: 1.5625rem;
      // position: absolute;
      // top: 30vh;
      // left: 30%;
      // transform: translate(-50%, -50%);
      // word-wrap: break-word;
      // word-break: normal;
      color: #c8e1db;
    }

    .MeetCloseBtn {
      width: 15%;
      height: 20%;
      position: absolute;
      top: 3%;
      left: 70%;
      // right: -2%;
      background: url("../../assets/metting/hys41.png") no-repeat;
      background-size: 60% 60%;
      cursor: pointer;
      z-index: 200;
    }
  }
  .invitation {
    // width: 928px;
    // height: 580px;
    width: 50vw;
    height: 30vh;
    position: absolute;
    // top: 65%;
    // left: 30%;
    top: 43vh;
    left: 30vw;
    z-index: 200;
    transform: translate(-50%, -50%);
    // background: url("../../assets/metting/hys37.png") no-repeat;
    // background: url("../../assets/metting/czts.png") no-repeat;
    // background-size: 100% 100%;
    // background-size: 30% 30%;
    // transition: all 0.5s;

    .sharelink {
      width: 30vw;
      height: 50vh;
     
      .text {
        width: 35vw;
        height: 20vh;
        font-size: 2vh;
        background: url("../../assets/metting/dbwenzi2.png");
        background-size: 100% 100%;
        line-height: 20vh;
        p {
          color: #c8e1db;
        }
        
      }
      .wasd {
        width: 30vw;
        height: 30vh;
        background: url("../../assets/metting/czts.png") no-repeat;
        background-size: 60% 60%;
        
      }
      // width: 450px;
      // height: 9.6875rem;
      // color: #c8e1db;
      // font-size: 1vh;
      // margin-top: 1.25rem;
      // margin-left: 1.25rem;
      // position: absolute;
      // text-align: center;
      // top: 50%;
      // left: 50%;
      // top: 0%;
      // left: 25%;
      // transform: translate(-50%, -50%);
      // word-wrap: break-word;
      // word-break: normal;
    }

    .MeetCloseBtn {
      width: 15%;
      height: 20%;
      position: absolute;
      top: 5%;
      left: 55%;
      // right: -2%;
      background: url("../../assets/metting/hys41.png") no-repeat;
      background-size: 60% 60%;
      cursor: pointer;
      z-index: 200;
    }
  }

  .meetingset {
    width: 920px;
    height: 580px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 200;
    transform: translate(-50%, -50%);
    background: url("../../assets/metting/hys37.png") no-repeat;
    background-size: 100% 100%;
    transition: all 0.5s;

    .MeetTitle {
      width: 61%;
      height: 14%;
      position: absolute;
      top: -12%;
      left: -5%;
      background: url("../../assets/metting/hys39.png") no-repeat;
      background-size: 100% 100%;
    }

    .MeetCloseBtn {
      width: 15%;
      height: 20%;
      position: absolute;
      top: -2%;
      right: -2%;
      background: url("../../assets/metting/hys41.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 100;
    }

    .MeetContent {
      width: 100%;
      height: 100%;
      position: relative;

      .nameshow {
        width: 90%;
        display: flex;
        position: absolute;
        top: 10%;
        left: 10%;

        span {
          margin: 0 10px;
          font-size: 24px;
          color: #fff;
          text-shadow: 0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199),
            0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199); //设置发光效果
        }

        .meetswich {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .camerashow {
        width: 90%;
        display: flex;
        position: absolute;
        top: 19%;
        left: 10%;

        span {
          margin: 0 10px;
          font-size: 24px;
          color: #fff;
          text-shadow: 0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199),
            0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199); //设置发光效果
        }

        .meetswich {
          width: 37%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .allowshow {
        width: 90%;
        display: flex;
        position: absolute;
        top: 37%;
        left: 10%;

        span {
          margin: 0 10px;
          font-size: 24px;
          color: #fff;
          text-shadow: 0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199),
            0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199); //设置发光效果
        }

        .meetswich {
          width: 31%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .backshow {
        width: 90%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 46%;
        left: 10%;

        span {
          margin: 0 10px;
          font-size: 24px;
          color: #fff;
          text-shadow: 0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199),
            0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199); //设置发光效果
        }

        .meetswich {
          width: 42%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .backimg {
            width: 165px;
            height: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../assets/metting/hys28.png") no-repeat;
            background-size: 100% 100%;
          }

          .upload {
            width: 72px;
            height: 42px;
            background: url("../../assets/metting/hys59.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .speakshow {
        width: 90%;
        display: flex;
        position: absolute;
        top: 28%;
        left: 10%;

        span {
          margin: 0 10px;
          font-size: 24px;
          color: #fff;
          text-shadow: 0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199),
            0 0 6px rgb(46, 141, 199), 0 0 6px rgb(46, 141, 199); //设置发光效果
        }

        .meetswich {
          width: 63%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .overBtn {
        cursor: pointer;
        width: 30%;
        height: 12%;
        bottom: 18%;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background: url("../../assets/metting/hys64.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.remote_stream {
  display: block;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  background: #a5c7cf;
  // width: 100%;
  // height: calc(100% - 60px);
}
.equipment-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 530px;
  height: 10%;
  z-index: 100;
  // background: url("../../assets/metting/hys15.png") no-repeat;
  // background-size: 100% 100%;

  .equipmentbox {
    width: 80%;
    height: 100%;
    margin-left: 85px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .member,
    .microphone,
    .vidicon,
    .vidiconclose,
    .expression,
    .expressionclose,
    .expressionforbid,
    .StopSpaak,
    .el-dropdown {
      width: 15%;
      height: 80%;
      cursor: pointer;
      display: inline-block;
      // background: #aaa;
      background-size: 100% 100%;
      margin-top: 16px;
    }

    .member {
      background: url("../../assets/metting/hys16.png") no-repeat;
    }

    .microphone {
      // background: url("../../assets/metting/hys18.png") no-repeat;
    }

    .vidicon {
      background: url("../../assets/metting/hys21.png") no-repeat;
    }

    .vidiconclose {
      // background: url("../../assets/metting/hys20.png") no-repeat;
    }

    .StopSpaak {
      // background: url("../../assets/metting/hys52close.png") no-repeat;
    }

    .el-dropdown {
      // background: url("../../assets/metting/menu.png") no-repeat;
      background-size: 100% 100% !important;
    }

    .expression {
      // background: url("../../assets/metting/hys22.png") no-repeat;
    }

    .expressionclose {
      // background: url("../../assets/metting/hys22close.png") no-repeat;
    }

    .expressionforbid {
      background: url("../../assets/metting/hys22.png") no-repeat;
    }
  }
}

.personnel {
  width: 928px;
  height: 580px;
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../assets/metting/hys37.png") no-repeat;
  background-size: 100% 100%;
  // transition: all 0.5s;

  .MeetTitle {
    width: 61%;
    height: 14%;
    position: absolute;
    top: -12%;
    left: -5%;
    background: url("../../assets/metting/hys38.png") no-repeat;
    background-size: 100% 100%;
  }

  .MeetCloseBtn {
    width: 15%;
    height: 20%;
    position: absolute;
    top: -2%;
    right: -2%;
    background: url("../../assets/metting/hys41.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    z-index: 100;
  }

  .search {
    position: absolute;
    width: 80%;
    height: 48px;
    left: 50%;
    transform: translateX(-50%);
    top: 52px;
    z-index: 200;

    .SelectBtn {
      width: 6%;
      height: 100%;
      position: absolute;
      background: url("../../assets/metting/hys44.png") no-repeat;
      background-size: 100% 100%;
      top: 0;
      cursor: pointer;
      right: 5%;
    }
  }

  .segmentation {
    width: 90%;
    height: 2px;
    position: absolute;
    background: url("../../assets/metting/hys45.png") no-repeat;
    background-size: 100% 100%;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }

  .headertitle {
    margin-top: 15%;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #a5c7cf;
    font-size: 24px;
    padding-right: 65px;

    .nav_a {
      width: 25px;
      height: 25px;
      background: url("../../assets/metting/hys46.png") no-repeat;
      background-size: 100% 100%;
    }

    .nav_b,
    .nav_c,
    .nav_d,
    .nav_e,
    .nav_f {
      margin: 0 4%;
    }
  }

  .popdata {
    margin-top: 2%;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #ffffff;
    font-size: 24px;

    .OneselfColor {
      width: 75px;
      color: rgb(64, 158, 255);
      font-size: 16px;
      text-align: center;
      margin: 0 3%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav_a {
      width: 25px;
      // height: 25px;
      // background: url("../../assets/metting/hys46.png") no-repeat;
      // background-size: 100% 100%;
    }

    .nav_c {
      width: 60px;
    }

    .nav_b {
      width: 75px;
    }

    .nav_b,
    .nav_c,
    .nav_d,
    .nav_e,
    .nav_f {
      text-align: center;
      display: inline-block;
      font-size: 16px;
      margin: 0 3%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .nav_d {
      width: 125px;
    }

    .nav_e {
      margin: 0 3%;
    }

    .nav_f {
      margin: 0 3%;
    }

    .nav_g {
      width: 10%;
      height: 45px;
      background: url("../../assets/metting/hys50.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .footcontext {
    width: 90%;
    height: 10%;
    position: absolute;
    justify-content: space-between;
    display: flex;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);

    .footleft {
      width: 45%;
      height: 100%;
      // background: #aaa;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #a5c7cf;

      // .foot_a {}

      .foot_ba {
        width: 14%;
        height: 100%;
        background: url("../../assets/metting/hys53.png") no-repeat;
        background-size: 100% 100%;
      }

      .foot_bA {
        width: 14%;
        height: 100%;
        background: url("../../assets/metting/hys52.png") no-repeat;
        background-size: 100% 100%;
      }

      .foot_c {
        color: #ffffff;
      }
    }

    .footright {
      width: 45%;
      height: 100%;
      // background: #aaa;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 3%;
      font-size: 24px;
      color: #a5c7cf;

      // .foot_a {}

      .foot_b {
        width: 14%;
        height: 100%;
        background: url("../../assets/metting/hys55.png") no-repeat;
        background-size: 100% 100%;
      }

      .foot_bb {
        width: 14%;
        height: 100%;
        background: url("../../assets/metting/hys54.png") no-repeat;
        background-size: 100% 100%;
      }

      .foot_c {
        color: #ffffff;
      }
    }
  }
}

.Fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  position: absolute;
  background: #aaa;
  z-index: 150;
  bottom: 0px;
  right: 0px;
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;

  /* Hide horizontal scrollbar */
  .Pswitch {
    width: 30px;
    height: 30px;
    background: #04be02;
    cursor: pointer;
    position: absolute;
    z-index: 150;
    bottom: 0px;
    right: 0px;
  }
}

.screen {
  width: 300px;
  height: 240px;
  position: absolute;
  top: 5px;
  right: 5px;
  // transform: translate(-50%, -50%);
  z-index: 150;
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  background: rgba(157, 144, 144, 0.2);

  .Pswitch {
    width: 50px;
    height: 50px;
    background: url("../../assets/metting/hys81.png");
    background-size: 100% 100%;
    cursor: pointer;
    position: absolute;
    z-index: 1001;
    bottom: 0px;
    right: 0px;
  }
}

::v-deep .el-dialog {
  width: 500px;
}
</style>
