import Vue from 'vue';
// 盒子拖拽
//使用Vue.directive()定义一个全局指令
export const drag = Vue.directive('drag', {
  bind: function (el) {
    el.style.cursor = "move"; //鼠标样式变move样式
  },
  //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
  inserted: function (el) {
    el.onmousedown = function (e) {
      var distX = e.pageX - el.offsetLeft;
      var distY = e.pageY - el.offsetTop;
      console.log("元素本身的高：" + el.clientHeight + ",元素本身的宽：" + el.clientWidth)
      
      if (e.preventDefault) {
        e.preventDefault();
       } else{
        e.returnValue=false;
       };//解决快速拖动滞后问题

      document.onmousemove = function (e) {

        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - distX;
        let top = e.clientY - distY;

        if (left <= 0) {
          left = 5; //设置成5是为了不离边缘太近
        } else if (left > document.documentElement.clientWidth - el.clientWidth) {
          //document.documentElement.clientWidth 屏幕的可视宽度
          left = document.documentElement.clientWidth - el.clientWidth - 5
        }
        if (top <= 0) {
          top = 5;
        } else if (top > document.documentElement.clientHeight - el.clientHeight) {
          top = document.documentElement.clientHeight - el.clientHeight - 5
        }
        el.style.left = left + 'px';
        el.style.top = top + 'px';
      }
      document.onmouseup = function () {
        document.onmousemove = document.onmouseup = null;
      }
    }
  },
  //当VNode更新的时候会执行updated，可以触发多次
  updated: function (el) {}
})
// 按钮节流
export const throttle= 
// 1.设置v-throttle自定义指令
Vue.directive('throttle', {
  bind: (el, binding) => {
    let throttleTime = binding.value; // 防抖时间
    if (!throttleTime) { // 用户若不设置防抖时间，则默认2s
      throttleTime = 2000;
    }
    let cbFun;
    el.addEventListener('click', event => {
      if (!cbFun) { // 第一次执行
        cbFun = setTimeout(() => {
          cbFun = null;
        }, throttleTime);
      } else {
        event && event.stopImmediatePropagation();
      }
    }, true);
  },
});
