<template>
    <div class="imgcontent">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="模型名称">
                <div class="fillcontain" v-loading="loading" element-loading-text="上传中，请稍后..."
                    element-loading-spinner="el-icon-loading">
                    <span v-if="!this.UploadShow">{{ this.fileName }}</span>
                    <el-upload ref="upload" v-show="this.UploadShow" class="upload" action="#" drag accept=".zip,Zip"
                        :auto-upload="false" :file-list="fileList" :show-file-list="false" :on-progress="handleProgress"
                        :onChange="pngChange" :before-upload="handleBeforeUpload" :limit="5">
                        <div class="upload-icon"></div>
                        <div class="upload-tip p1">点击上传</div>
                        <div class="upload-tip2 p1">（仅支持zip格式）</div>
                    </el-upload>
                </div>
            </el-form-item>
            <!-- <el-progress v-if="SenceFlag == true" :percentage="videoUploadPercent"></el-progress> -->
            <el-form-item label="名称" prop="Name">
                <el-input v-model="ruleForm.Name" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div style="text-align: center;">
            <!-- <el-button @click="UploadBtn">下一步</el-button> -->
            <!--  如何在使用VUE技术栈中对接3D场景进行性能优化 -->
            <div v-show="progressFlag">
                <el-progress :text-inside="true" :stroke-width="14" :percentage="progressPercent"></el-progress>
            </div>
            <ImgUpload :Getimgurl="Getimgurl"></ImgUpload>
        </div>
        <el-button @click="AddBtn('ruleForm')"> 添加</el-button>
    </div>
</template>
  
<script>
import ImgUpload from './SenceBackImg.vue';
import { SenceZipUp, Sencedata } from '../../utils/api/http.js';
export default {
    components: {
        ImgUpload
    },
    props: [''],
    data() {
        return {
            progressPercent: 0,
            progressFlag: false,
            loading: false,
            fileList: [],
            imgurl: '',
            filePath: '',
            fileName: '',
            tempScene: '',
            centerDialogVisible: false,
            UploadShow: true,
            ruleForm: {
                Name: '',
                package: '',
            },
            rules: {
                Name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                package: [
                    { required: true, message: '请上传场景模型文件', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            SenceFlag: true,
            videoUploadPercent: 10
        }
    },
    methods: {
        // 上传
        pngChange(file) {
            let fileType = ["zip",];
            this.fileCheckimg(file, fileType, 1);
            if (!this.imgType) {
            }
        },
        fileCheckimg(file, fileType, fileSide) {
            this.fileName = file.name;
            let suffix = file.name
                .substring(file.name.lastIndexOf(".") + 1)
                .toLowerCase(); // 防止有的文件后缀名是大写  好
            if (fileType.indexOf(suffix) < 0) {
                this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
                this.$message.error("文件类型格式不对！");
                return false;
            }
            // if (file.side > fileSide * 1024 * 1024) {
            //   this.$message.error(`文件大小不能超过 ${fileSide} MB！`);
            //   return;
            // }
            let params = {
                file: file.raw,
                // materialType: "0",
            };
            // const config = {
            //     onUploadProgress: (progressEvent) => {
            //         // progressEvent.loaded:已上传文件大小
            //         // progressEvent.total:被上传文件的总大小
            //         this.progressPercent = Number(
            //             ((progressEvent.loaded / progressEvent.total) * 90).toFixed(2)
            //         );
            //     },
            // };
            this.loading = true;
            SenceZipUp(params,).then((res) => {
                if (res.code == 200) {
                    this.$message.success("上传成功");
                    this.UploadShow = !this.UploadShow
                    this.filePath = res.data.filePath
                    const url = res.data.filePath + '/project.json';
                    fetch(url).then(response => response.json()).then(data => {
                        const senceurl = res.data.filePath + '/' + data.project.editingScene
                        console.log(senceurl);
                        fetch(senceurl).then(response => response.json()).then(data => {
                            this.tempScene = JSON.stringify(data)
                            console.log(this.tempScene);
                        }).catch(error => {
                            console.log('error');
                        })
                    }).catch(error => {
                        console.log('error',error);
                    })
                    this.centerDialogVisible = false
                    this.loading = false;
                }
            });
        },
        // 判断上传的是否为压缩包
        handleBeforeUpload(file) {
            var zip = file.name.substring(file.name.lastIndexOf(".") + 1);
            const suffix = zip === "zip";
            const suffix2 = zip === "ZIP";
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2) {
                this.$message.error("只能上传压缩包！");
                return false;
            }
            // 可以限制压缩包的大小
            // if (!isLt1M) {
            //   this.$message.error("上传压缩包大小不能超过 1MB!");
            // }
            return suffix || suffix2 || suffix3;
        },
        successUpload(response, file, fileList) {
            this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        },
        //   进度条显示
        handleProgress(event) {
            console.log(event);
        },
        //弹框关闭 
        CloseDialog() {
            this.fileList = [];
            this.UploadShow = true
        },
        // 获取子组件上传压缩包成功之后返回来的压缩包在线地址，
        Getimgurl(val) {
            console.log(val, 'imgurl');
            this.imgurl = val
        },
        // 上传模型全部数据
        AddBtn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.filePath && this.imgurl) {
                        let data = {
                            modelName: this.ruleForm.Name,
                            tempScene: this.tempScene,
                            logoImage: this.imgurl,
                            filePath: this.filePath
                        }
                        Sencedata(data).then(res => {
                            console.log(res);
                            this.$emit('GetTemplateList')
                            this.$emit('close')
                            // this.$parent.GetTemplateList()
                        })
                    } else {
                        this.$message({
                            message: '请上传场景模型或场景显示图',
                            type: 'warning'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        }
    },
    mounted() {
    }
}
</script>
  
<style scoped>
.imgcontent {
    width: 100%;
    height: 100%;
}

.imgBox {
    height: 100%;
    width: calc(100% - 10px);
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
}

.imgupload {
    position: relative;
    margin: 10px;
    width: 100px;
    height: 120px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    /* background: rgb(70, 80, 91); */
    font-size: 24px;
    color: #ccc;
}

.imguploadBtn {
    background: rgb(70, 80, 91);
    line-height: 120px;
}

.imgupload .ImgName {
    position: absolute;
    font-size: 16px;
    width: 100%;
    height: 24px;
    z-index: 1;
    bottom: 0;
    background: #ccc;
    color: #000;
}

.Buttonpage {
    text-align: center;
    /* position: absolute;
    bottom: 20px;
    margin-bottom: 0px; */
}

/* ::v-deep .el-upload {
    width: 50px;
    height: 50px;
}

::v-deep .el-upload-dragger {
    width: 100%;
    height: 50px;
}
.fillcontain{
    width: 100px;
    height:50px;
} */
</style>