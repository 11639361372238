import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    HotspotId: null,
    Yesedit: false,
    leftactive:'',
    pathData:'',
  },
  getters: {
  },
  mutations: {
    saveId(state, HotspotId) {
      state.HotspotId = HotspotId;
    },
    savePath(state, Yesedit) {
      console.log(state);
      state.Yesedit = Yesedit;
    },
    leftListBg(state, leftactive) {
      state.leftactive = leftactive;
    },
    SavepathData(state, pathData) {
      state.pathData = pathData;
    },
  },
  actions: {
  },
  modules: {
  }
})
