<template>

  <div class="Home">
    <div class="HeaderBox">
      <Header></Header>
    </div>
    <div class="Nav-Box">
      <!-- <div class="leftBox">
        <Left></Left>
      </div> -->
      <div class="CenterBox">
        <Centerbox ></Centerbox>
      </div>
      <div class="RightBox">
        <Right></Right>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/header.vue';
// import Left from '../components/left.vue';
import Centerbox from '../components/centerbox.vue';
import Right from '../components/right.vue';
import { DataList } from "../utils/api/http.js";
import {getStorage} from "../utils/token/token.js"
export default {
  name: 'HomeView',
  components: {
    Header,
    // Left,
    Centerbox,
    Right
  },
  data(){
    return{
      LeftList:[]
    }
  },
  methods:{
      // 刷新界面获取最新数据
      GetDataList() {
            let data = {
                composition: {
                    userId: "",
                    phone: "",
                    compositionId: getStorage('SecneId'),
                },
                pageNum: 1,
                pageSize: 1,
            }
            DataList(data).then(res => {
                if (res.code == 200) {
                    Storage.removeItem('UrlDAta');
                    window.localStorage.setItem('UrlDAta', res.data.list[0].modelInformation);
                    window.localStorage.setItem('SecneId', res.data.list[0].compositionId);
                    window.localStorage.setItem('SecneType', res.data.list[0].modelType);
                }
            })
        }
  },
  created(){
    // this.GetDataList()
  },
  mounted(){
      
  }
}
</script>
<style scoped>
.Home {
  width: 100%;
  min-width: 1280px;
  height: 100vh;
  background: #aaa;
}

.Nav-Box {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
}

.HeaderBox {
  width: 100%;
  height: 80px;
  background: rgb(38, 38, 38);
}

.leftBox {
  left: 0;
  width: 280px;
  height: 100%;
  /* overflow-y: scroll; */
  background: rgb(34, 34, 34);
}

.CenterBox {
  flex: 1;
  height: 100%;
}

.RightBox {
  right: 0;
  width: 280px;
  height: 100%;
  background: rgb(48, 48, 58);
}
</style>
