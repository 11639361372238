<template>
    <div>
      <h1>音乐</h1>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>